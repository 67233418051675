<div style="margin-top: 0px;">
  <nz-page-header-content>
    <nz-row [nzGutter]="16" nzJustify="center" nzType="flex">
      <nz-col [nzSpan]="6">
        <nz-card>
          <nz-statistic [nzTitle]="'Active Users'" [nzValue]="1949101 | number"></nz-statistic>
        </nz-card>

      </nz-col>
      <nz-col [nzSpan]="6">
        <nz-card>
          <nz-statistic [nzPrefix]="'K'" [nzTitle]="'Account Balance'"
                        [nzValue]="2019.111 | number: '1.0-2'"></nz-statistic>
        </nz-card>

      </nz-col>
      <nz-col [nzSpan]="6">
        <nz-card>
          <nz-statistic [nzTitle]="'Successful Transactions'" [nzValue]="19491 | number: '1.0-2'" nzShowIcon
                        nzType="success"></nz-statistic>
        </nz-card>
      </nz-col>
      <nz-col [nzSpan]="6">
        <nz-card>
          <nz-statistic [nzTitle]="'Unsuccessful Transactions'" [nzValue]="563 | number: '1.0-2'"></nz-statistic>
        </nz-card>
      </nz-col>
    </nz-row>

  </nz-page-header-content>
</div>
<nz-divider nzOrientation="left" nzText="Usage Summary" style="margin-top: 30px;"></nz-divider>
<div style="margin-top: 30px;">
  <nz-row [nzGutter]="16">
    <nz-col [nzSpan]="12">
      <nz-card>
        <nz-statistic
          [nzPrefix]="prefixTplOne"
          [nzSuffix]="'%'"
          [nzTitle]="'Successful Messages'"
          [nzValueStyle]="{ color: '#3F8600' }"
          [nzValue]="76.28 | number: '1.0-2'"
        >
        </nz-statistic>
        <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
      </nz-card>
    </nz-col>
    <nz-col [nzSpan]="12">
      <nz-card>
        <nz-statistic
          [nzPrefix]="prefixTplTwo"
          [nzSuffix]="'%'"
          [nzTitle]="'Unsuccessful Messages'"
          [nzValueStyle]="{ color: '#CF1322' }"
          [nzValue]="11.3 | number: '1.0-2'"
        >
        </nz-statistic>
        <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
      </nz-card>
    </nz-col>
  </nz-row>
</div>
<highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
                  style="width: 100%; height: 350px; margin-top: 40px; display: block; ">
  <button nz-button>Explore Insights</button>
</highcharts-chart>


<!-- <div nz-row nzType="flex" nzJustify="space-around" style="margin-top: 30px;">
    <nz-col [nzSpan]="6">
        <nz-alert
          nzType="success"
          nzMessage="Successull Messages"
          nzDescription="19491"
          nzShowIcon
        >

        </nz-alert>
    </nz-col>
    <nz-col [nzSpan]="6">
        <nz-alert nzType="error" nzMessage="Unsuccessful Messages" nzDescription="672" nzShowIcon>
        </nz-alert>
    </nz-col>


</div> -->


<nz-footer>Bulk SMS ©2020 Powered By Hobbiton</nz-footer>
