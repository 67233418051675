<nz-divider nzOrientation="left" nzText="Quickly Send A Message"
            style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;"></nz-divider>
<nz-card>
  <div class="container">
    <form (ngSubmit)="submitForm(validateForm.value)" [formGroup]="validateForm" nz-form>
      <div nz-row nzGutter="8">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter Originator!">
              <input
                formControlName="originatorId"
                maxlength="8"
                nz-input placeholder="Enter Originator"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="12">
          <nz-form-item>
            <!-- <nz-form-label>Recipient</nz-form-label> -->
            <nz-form-control nzErrorTip="Please enter Recipient's Number!">
              <nz-input-group nzAddOnBefore="+260">
                <input formControlName="msisdn"
                       maxlength="9"
                       nz-input
                       placeholder="Enter mobile number"/>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <nz-form-item>
          <!-- <nz-form-label>Message</nz-form-label> -->
          <nz-form-control nzErrorTip="Please type your message here!">
                <textarea
                  formControlName="message"
                  nz-input
                  placeholder="Type your message here"
                  rows="8"
                ></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-row nzJustify="end" nzType="flex">
        <nz-form-item>
          <nz-form-control>
            <button
              nz-button
              nzShape="round"
              nzType="Default"
              style="width: 200px;"
            >
              <i nz-icon nzTheme="outline" nzType="mail"></i>SEND
            </button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </div>

  <!-- <form action="">
  <input
    #inputElement
    style="width: 500px"
    nz-input
    nz-tooltip
    nzTrigger="focus"
    nzPlacement="topLeft"
    nzOverlayClassName="numeric-input"
    [ngModel]="value"
    [nzTitle]="title"
    placeholder="Input a number"
    (ngModelChange)="onChange($event)"
    (blur)="onBlur()"
  />
</form> -->
</nz-card>

<nz-footer>Bulk SMS ©2024 Powered By Hobbiton</nz-footer>
