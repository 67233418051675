<!-- ======= Services Section ======= -->
<section class="services" id="services">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Services</h2>
      <!-- <p>Magnam dolores commodi suscipit eius consequatur ex aliquid fug</p> -->
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i nz-icon nzTheme="outline" nzType="message"></i></div>
          <h4 class="title"><a href="">Bulk Sms</a></h4>
          <p class="description">Send bulk sms from our dashboard</p>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
          <div class="icon"><i nz-icon nzTheme="outline" nzType="code"></i></div>
          <h4 class="title"><a href="">System Integration</a></h4>
          <p class="description">Connect your application to our API and get send messages to anyone</p>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
          <div class="icon"><i nz-icon nzTheme="outline" nzType="user"></i></div>
          <h4 class="title"><a href="">Personalized Messages</a></h4>
          <p class="description">Get started and easily send messages from our dahboard</p>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
        <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
          <div class="icon"><i nz-icon nzTheme="outline" nzType="usergroup-add"></i></div>
          <h4 class="title"><a href="">Save contacts</a></h4>
          <p class="description">Save contacts and groups so you can easily send messages by selecting recipients</p>
        </div>
      </div>

    </div>

  </div>
</section><!-- End Services Section -->
