<!-- ======= Pricing Section ======= -->
<section class="pricing" id="pricing">
  <div class="container">

    <div class="section-title">
      <h2>Pricing</h2>
      <p>Send bulk sms at a very affordable price...</p>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6">
        <div class="box" data-aos="zoom-in-right" data-aos-delay="200">
          <h3>Below 50,000 SMSes</h3>
          <h4><sup>ZMW</sup>0.20<span> / sms</span></h4>
          <ul>
            <li>Guaranteed Service Level Agreements</li>
            <li>Dedicated Account Manager</li>
            <li>Custom routing configuration</li>
            <li class="na">Was at ZMW 0.30</li>
          </ul>
          <div class="btn-wrap">
            <a class="btn-buy" href="#">Buy Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
        <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
          <h3>Between 50,000 and 100,000 SMSes</h3>
          <h4><sup>ZMW</sup>0.18<span> / sms</span></h4>
          <ul>
            <li>Guaranteed Service Level Agreements</li>
            <li>Dedicated Account Manager</li>
            <li>Custom routing configuration</li>
            <li class="na">Was at ZMW 0.20</li>
          </ul>
          <div class="btn-wrap">
            <a class="btn-buy" href="#">Buy Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
        <div class="box" data-aos="zoom-in-left" data-aos-delay="200">
          <h3>Above 100,000 SMSes</h3>
          <h4><sup>ZMW</sup>0.16<span> / sms</span></h4>
          <ul>
            <li>Guaranteed Service Level Agreements</li>
            <li>Dedicated Account Manager</li>
            <li>Custom routing configuration</li>
          </ul>
          <div class="btn-wrap">
            <a class="btn-buy" href="#">Buy Now</a>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>

<!-- End Pricing Section -->
