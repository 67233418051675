import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {GroupDTO} from "../models/group.dto";
import {BehaviorSubject, Observable} from "rxjs";
import {Group} from "../models/group.model";
import {BaseUrlService} from "./baseUrl.service";


@Injectable({
  providedIn: "root",
})
export class GroupsService {

  private groupIdSource = new BehaviorSubject<number>(10)
  currentGroupId$ = this.groupIdSource.asObservable();


  constructor(private http: HttpClient,
  private readonly baseUrlService: BaseUrlService

) {
  }

  addGroup(userId: number, group: GroupDTO): Observable<GroupDTO> {

    return this.http.post<Group>(
      `${this.baseUrlService.BASE_URL}/users/groups/${userId}`,
      group
    );
  }

  getAllGroups(userId: number) {
    return this.http.get<Group[]>(`${this.baseUrlService.BASE_URL}/users/groups/${userId}`);
  }

  getGroupById(groupId: number): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrlService.BASE_URL}/groups/get-group/${groupId}`);
  }

  changeGroupNumber(groupId: number) {
    this.groupIdSource.next(groupId);
  }


  deleteGroup(groupId: number) {
    return this.http.delete(`${this.baseUrlService.BASE_URL}/groups/${groupId}`);
  }

  updateGroup(groupId: number, group: GroupDTO) {
    return this.http.patch(`${this.baseUrlService.BASE_URL}/groups/${groupId}`, group);
  }
}
