import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {WelcomeComponent} from "./pages/welcome/welcome.component";
import {QuickSmsComponent} from "./pages/quick-sms/quick-sms.component";
import {InsightsComponent} from "./pages/insights/insights.component";
import {SmsOverViewComponent} from "./sms-over-view/sms-over-view.component";
import {PersonalizedSmsComponent} from "./pages/personalized-sms/personalized-sms.component";
import {LoginComponent} from "./login/login.component";
import {AuthGuardService as AuthGuard} from "./guards/auth-guard.service";
import {DevelopersComponent} from './pages/developers/developers.component';
import {MyAccountComponent} from './pages/my-account/my-account.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {TermsOfUseComponent} from './landing-page/components/terms-of-use/terms-of-use.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NewPaswordComponent} from './new-pasword/new-pasword.component';
import {EmailSentMessageComponent} from './email-sent-message/email-sent-message.component';
import {ValidateEmailSentMessageComponent} from './validate-email-sent-message/validate-email-sent-message.component';


const routes: Routes = [
  {path: "", pathMatch: "full", redirectTo: "bulksms"},
  {path: "bulksms", pathMatch: "full", component: LandingPageComponent},
  {path: "terms-of-use", pathMatch: "full", component: TermsOfUseComponent},
  {path: "login", pathMatch: "full", component: LoginComponent},
  {path: "forgot-password", pathMatch: "full", component: ForgotPasswordComponent},
  {path: "new-password", pathMatch: "full", component: NewPaswordComponent},
  {path: "email-sent", pathMatch: "full", component: EmailSentMessageComponent},
  {path: "validate-email-sent", pathMatch: "full", component: ValidateEmailSentMessageComponent},
  {path: "new-password/:id", pathMatch: "full", component: NewPaswordComponent},
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {path: "", pathMatch: "full", redirectTo: "welcome"},
      {path: "welcome", pathMatch: "full", component: WelcomeComponent, canActivate: [AuthGuard],},
      {path: "quick-send", pathMatch: "full", component: QuickSmsComponent, canActivate: [AuthGuard],},
      {path: "insights", pathMatch: "full", component: InsightsComponent, canActivate: [AuthGuard],},
      {path: "my-account", pathMatch: "full", component: MyAccountComponent, canActivate: [AuthGuard],},
      {
        path: "sms-overview",
        pathMatch: "full",
        component: SmsOverViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "bulk-sms",
        loadChildren: () =>
          import("./pages/bulk-sms/bulk-sms.module").then(
            (m) => m.BulkSmsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "contacts",
        loadChildren: () =>
          import("./pages/contacts/contacts.module").then(
            (m) => m.ContactsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: "personalized-sms",
        pathMatch: "full",
        component: PersonalizedSmsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "developers",
        pathMatch: "full",
        component: DevelopersComponent,
        canActivate: [AuthGuard]
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
