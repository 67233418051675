import {Component, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {NzMessageService} from "ng-zorro-antd";
import {Group} from "src/app/models/group.model";
import {FormGroup} from "@angular/forms";
import {GroupsService} from "src/app/services/groups.service";
import {Router} from "@angular/router";
import {take} from "rxjs/operators";
import {ContactsService} from 'src/app/services/contacts.service';
import {UserService} from 'src/app/services/user.service';
import {Transactions} from '../../models/transactions.model';
import {SmsPaymentService} from '../../services/sms-payment.service';

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {

  spinning = true;
  isUpdateGroupModalVisible = false;
  validateUpdateGroupForm: FormGroup;
  time: string;
  groupsList: Group[] = [];
  searchGroupsList: Group[] = [];
  searchString: string;
  groupId: number;
  isOkLoading = false;
  userItems: any;
  name: string;
  contactCount: any;
  account: any;
  balance: number;
  successMessageCount: number;
  failedMessageCount: number;
  totalMessageCount: number;
  failRate: number;
  sucessRate: number;
  transactionsTableData: Transactions[] = [];
  successSpinning = true;
  unsuccesfullSpinning = true;
  accountBalSpinning = true;
  contactsSpinning = true;

  constructor(
    private http: HttpClient,
    private msg: NzMessageService,
    private readonly groupService: GroupsService,
    private readonly contactService: ContactsService,
    private readonly userService: UserService,
    private readonly smsPaymentService: SmsPaymentService,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "Good Afternoon" : "Good Morning";
    this.time = ampm;


    this.userItems = this.getFromLocalStrorage();
    this.name = this.userItems.name.split(' ')[0];
    const userId = this.userItems.id;
    console.log(userId);

    this.contactService.getContactCount(userId).subscribe(res => {
      this.contactCount = res;
      this.contactsSpinning = false;
    })

    this.smsPaymentService.getPaymentTransactions(this.userItems.userId).subscribe((data) => {
      this.transactionsTableData = data;
    })

    this.groupService
      .getAllGroups(userId)
      .pipe(take(5))
      .subscribe((data) => {
        this.groupsList = data;
        this.searchGroupsList = data;
      });

    this.userService.getTotalMessageCount(this.userItems.userId).subscribe(res => {
      this.totalMessageCount = res
      this.spinning = false;
    });

    this.userService.getAccountBalance(this.userItems.userId).subscribe(res => {

      this.account = res
      this.balance = this.account.balance;
      this.accountBalSpinning = false;
    });

    this.userService.getSuccessMessageCount(this.userItems.userId).subscribe(count => {
      const res = count;
      this.sucessRate = (count / this.totalMessageCount) * 100
      this.successMessageCount = res;
      this.successSpinning = false;
    })

    this.userService.getFailedMessageCount(this.userItems.userId).subscribe(count => {
      this.failRate = (count / this.totalMessageCount) * 100
      this.failedMessageCount = count;
      this.unsuccesfullSpinning = false;
    })

  }

  public getFromLocalStrorage() {
    const users = JSON.parse(localStorage.getItem('currentUser'));
    console.log('============');
    console.log(users);
    return users;
  }

  showUpdateGroupModal(groupId: number): void {
    this.isUpdateGroupModalVisible = true;
    this.groupId = groupId;
  }

  handleUpdateGroupCancel() {
    this.isUpdateGroupModalVisible = false;
  }

  handleUpdateGroupOk(group) {
    console.log("Okay Clicked", group);
    for (const i in this.validateUpdateGroupForm.controls) {
      this.validateUpdateGroupForm.controls[i].markAsDirty();
      this.validateUpdateGroupForm.controls[i].updateValueAndValidity();
    }
    this.isOkLoading = true;
    if (this.validateUpdateGroupForm.valid) {
      return this.groupService
        .updateGroup(this.groupId, group)
        .subscribe((res) => {
          console.log(res);
          this.isOkLoading = false;
          this.msg.success(`Group updated successfully.`);
          this.isUpdateGroupModalVisible = false;
        });
    } else {
      this.isOkLoading = false;
    }
  }

  searchAllGroups(value: string): void {
    if (value === "" || !value) {
      this.searchGroupsList = this.groupsList;
    }

    this.searchGroupsList = this.searchGroupsList.filter((group) => {
      return group.name.toLowerCase().includes(value.toLowerCase());
    });
  }

  viewGroupMembers(groupId: number) {
    this.groupService.changeGroupNumber(groupId);
    this.router.navigateByUrl("dashboard/contacts/all-groups");
  }

  deletGroup(groupId: number) {
    this.groupService.deleteGroup(groupId).subscribe((res) => {
      console.log(res);
      this.createMessage();
    });
  }

  createMessage(): void {
    this.msg.success(`Group Deleted Successfully.`);
  }

}
