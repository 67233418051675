<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-lg-6 text-lg-left text-center">
        <div class="copyright">
          &copy; Copyright <strong>Tumani</strong>. All Rights Reserved
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/vesperr-free-bootstrap-template/ -->
          Powered by <a href="https://hobbiton.co.zm">Hobbiton Technologies</a>
        </div>
      </div>
      <div class="col-lg-6">
        <nav class="footer-links text-lg-right text-center pt-2 pt-lg-0">
          <a class="scrollto" fragment="hero" href="#hero" routerLink="/bulksms">Home</a>
          <a class="scrollto" fragment="about" href="#about" routerLink="/bulksms">About</a>
          <a href="#">Privacy Policy</a>
          <a routerLink="../terms-of-use">Terms of Use</a>
        </nav>
      </div>
    </div>
  </div>
</footer><!-- End Footer -->
