<div>
  <nz-card style="margin-top: 1px;">
    <nz-page-header>

      <!--avatar-->
      <nz-avatar nz-page-header-avatar nzSrc="assets/logo.jpeg"></nz-avatar>

      <!--title-->
      <nz-page-header-title><h1 class="css-c7tawj e1janbhz1"><span>{{ time }}, {{ name }}</span></h1>
      </nz-page-header-title>


      <!--content-->
      <nz-page-header-content>
        <div class="wrap">
          <div class="content">
            <div class="content">
              <p nz-paragraph
                 style="font-size: 20px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                Welcome back to your personalized Dashboard. Check out useful tumani solutions tailored to your account.
              </p>
              <!--  <p nz-paragraph>
                 Ant Design's design team preferred to design with the HSB color model, which makes it easier for
                 designers to have a clear psychological expectation of color when adjusting colors, as well as
                 facilitate communication in teams.
               </p> -->
              <!-- <p class="content-link">
                <a>
                  <img src="https://gw.alipayobjects.com/zos/rmsportal/MjEImQtenlyueSmVEfUD.svg" alt="start" />Quick
                  Start
                </a>
                <a>
                  <img src="https://gw.alipayobjects.com/zos/rmsportal/NbuDUAuBlIApFuDvWiND.svg" alt="info" />Product
                  Info
                </a>
                <a>
                  <img src="https://gw.alipayobjects.com/zos/rmsportal/ohOEPSYdDTNnyMbGuyLb.svg" alt="doc" />Product Doc
                </a>
              </p> -->
            </div>
          </div>
          <div class="extra-content">
            <img alt="content"
                 src="assets/undraw_message_sent_1030.png"
                 style="width: 200px;"
            />
          </div>
        </div>
      </nz-page-header-content>
    </nz-page-header>
  </nz-card>

  <nz-divider nzOrientation="left" nzText="Portfolio Summary"></nz-divider>

  <div style="margin-top: 1px;">
    <nz-page-header-content>
      <nz-row [nzGutter]="16" nzJustify="center" nzType="flex">
        <nz-col [nzSpan]="6">
          <nz-spin [nzSpinning]="contactsSpinning" nzTip="Loading...">
            <nz-card>
              <nz-statistic [nzTitle]="'Contacts'" [nzValue]="contactCount | number"></nz-statistic>
            </nz-card>
          </nz-spin>


        </nz-col>
        <nz-col [nzSpan]="6">
          <nz-spin [nzSpinning]="accountBalSpinning" nzTip="Loading...">
            <nz-card>
              <nz-statistic [nzTitle]="'Sms Balance'"
                            [nzValue]="balance ? balance  : '0.0' | number: '1.0-2'"></nz-statistic>
            </nz-card>
          </nz-spin>


        </nz-col>
        <nz-col [nzSpan]="6">
          <nz-spin [nzSpinning]="successSpinning" nzTip="Loading...">
            <nz-card>
              <nz-statistic
                [nzTitle]="'Successful Transactions'"
                [nzValue]="successMessageCount ? successMessageCount  : '0' | number: '1.0-2'"
              ></nz-statistic>
              <!-- <nz-statistic [nzValue]="this.successMessageCount" [nzTitle]="'Successful Transactions'"></nz-statistic> -->
            </nz-card>
          </nz-spin>

        </nz-col>
        <nz-col [nzSpan]="6">
          <nz-spin [nzSpinning]="unsuccesfullSpinning" nzTip="Loading...">
            <nz-card>
              <nz-statistic [nzTitle]="'Unsuccessful Transactions'"
                            [nzValue]="failedMessageCount ? failedMessageCount  : '0' | number: '1.0-2'"></nz-statistic>
            </nz-card>
          </nz-spin>

        </nz-col>
      </nz-row>

    </nz-page-header-content>
  </div>


  <nz-row>
    <div nz-col nzSpan="6">
      <div class="css-ghonet e1bmxbt02">
        <span>Groups</span>

      </div>
      <div class="seach">
        <nz-input-group [nzSuffix]="suffixIconSearch" style="width: 100%;">
          <input (ngModelChange)="searchAllGroups($event)" [(ngModel)]="searchString" nz-input placeholder="input search text"
                 type="text"/>
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
      </div>
      <div class="group-list">
        <cdk-virtual-scroll-viewport itemSize="5" style="height: 300px;">
          <!-- <nz-list nzItemLayout="horizontal">
            <nz-list-item *cdkVirtualFor="let item of searchGroupsList" [nzActions]="opAction" [nzContent]="item.name">
            </nz-list-item>
          </nz-list> -->

          <ul [nzDataSource]="searchGroupsList" [nzRenderItem]="largeItem" nz-list nzBordered nzSize="large">
            <ng-template #largeItem let-item>
              <li
                [nzActions]="[opAction]"
                [nzContent]="item.name"
                nz-list-item
                nzNoFlex
              ></li>
              <ng-template #opAction>
                <i [nzDropdownMenu]="menu4" nz-dropdown nz-icon nzTheme="outline" nzType="unordered-list"></i>
              </ng-template>
              <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                  <li (click)="viewGroupMembers(item.id)" nz-menu-item><i nz-icon nzTheme="outline" nzType="eye"></i>
                    View Members
                  </li>
                  <li (click)="showUpdateGroupModal(item.id)" nz-menu-item><i nz-icon nzTheme="outline"
                                                                              nzType="edit"></i>Edit
                  </li>
                  <li nz-menu-item><i nz-icon nzTheme="outline" nzType="export"></i>Export xlsx</li>
                  <li (click)="deletGroup(item.id)" nz-menu-item><i nz-icon nzTheme="outline" nzType="delete"></i>
                    Delete
                  </li>
                  <!-- <li nz-menu-item><i nz-icon nzType="mail" nzTheme="outline"></i>Send Campaign</li> -->
                </ul>
              </nz-dropdown-menu>
            </ng-template>
          </ul>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>

    <div nz-col nzJustify="space-around" nzSpan="17" style="padding: 50px;">
      <div style="background: #ECECEC; padding: 30px;">
        <nz-row [nzGutter]="16">
          <nz-col [nzSpan]="12">
            <nz-spin [nzSpinning]="successSpinning" nzTip="Loading...">
              <nz-card>
                <nz-statistic
                  [nzPrefix]="prefixTplOne"
                  [nzSuffix]="'%'"
                  [nzTitle]="'Successfull Mesages'"
                  [nzValueStyle]="{ color: '#3F8600' }"
                  [nzValue]="this.sucessRate | number: '1.0-2'"
                >
                </nz-statistic>
                <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
              </nz-card>
            </nz-spin>

          </nz-col>
          <nz-col [nzSpan]="12">
            <nz-spin [nzSpinning]="unsuccesfullSpinning" nzTip="Loading...">
              <nz-card>
                <nz-statistic
                  [nzPrefix]="prefixTplTwo"
                  [nzSuffix]="'%'"
                  [nzTitle]="'Failed Message'"
                  [nzValueStyle]="{ color: '#CF1322' }"
                  [nzValue]="this.failRate | number: '1.0-2'"
                >
                </nz-statistic>
                <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
              </nz-card>
            </nz-spin>

          </nz-col>
        </nz-row>
      </div>
    </div>

    <div nz-col nzJustify="space-around" nzSpan="17" style="padding: 50px;">
      <!-- <nz-card>
        <nz-table #smallTable nzSize="small" [nzData]="this.transactionsTableData">
          <thead>
            <tr>
              <th>Top Up Number</th>
              <th>Amount</th>
              <th>Number of Messages</th>
               <th>MNO transaction id</th> -->
      <!-- <th>Tumani transaction id</th>
      <th>Transaction Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of smallTable.data">
      <td>{{ data.MSISDN }}</td>
      <td>{{ data.amount }}</td>
      <td>{{ data.sms_number }}</td>
      <td>{{ data.request_id }}</td>
      <td>{{ data.status }}</td> -->
      <!-- <td></td>
    </tr>
  </tbody>
</nz-table>
</nz-card> -->
    </div>
  </nz-row>

</div>
