<section class="pricing" id="pricing">
  <div class="container">

    <div class="section-title">
      <h2>Developers</h2>
      <p>Integrate seemlessly into any application</p>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6">
        <div class="box" data-aos="zoom-in-right" data-aos-delay="200">
          <h3>Integrate into any application</h3>
          <ul>
            <li>Web based applications</li>
            <li>Mobile applications</li>
            <!-- <li>iOS applications</li> -->
          </ul>
          <div class="btn-wrap">
            <a (click)="showModal()" class="btn-buy">Get Started</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
        <div class="box recommended" data-aos="zoom-in" data-aos-delay="100">
          <h3>Simply send an XML Request</h3>
          <ul>
            <!-- <li>Guaranteed Service Level Agreements</li>
            <li>Dedicated Account Manager</li>
            <li>Custom routing configuration</li> -->
            <img src="./../../../../assets/sample-bulk-sms-request.PNG">
          </ul>
          <div class="btn-wrap">
            <a (click)="showModal()" class="btn-buy">Get Started</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
        <div class="box" data-aos="zoom-in-left" data-aos-delay="200">
          <h3>Get Dedicated Support</h3>
          <ul>
            <li>
              <i nz-icon nzTheme="outline" nzType="mail"></i>
              support@tumanisms.com
            </li>
            <li>
              <i nz-icon nzTheme="outline" nzType="mail"></i>
              customercare@tumanisms.com
            </li>
            <li>
              <i nz-icon nzTheme="outline" nzType="phone"></i>
              +260973548906
            </li>
            <li>
              <i nz-icon nzTheme="outline" nzType="phone"></i>
              +260977872726
            </li>
          </ul>
          <div class="btn-wrap">
            <a (click)="showModal()" class="btn-buy">Get Started</a>
          </div>
        </div>
      </div>

    </div>

  </div>
</section>

<nz-modal
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk(validateForm.value)"
  [(nzVisible)]="isVisible"
  [nzOkLoading]="isOkLoading"
  nzTitle="Register"
  style="width: 800px;"
>
  <form [formGroup]="validateForm" nz-form>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="name" nzRequired>Name</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input you name">
        <input formControlName="name" id="name" nz-input/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="email" nzRequired>E-mail</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid E-mail!">
        <input formControlName="email" id="email" nz-input/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="password" nzRequired>Password</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your password! minimum characters should be 6">
        <input
          (ngModelChange)="updateConfirmValidator()"
          formControlName="password"
          id="password"
          minlength="6"
          nz-input
          type="password"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="checkPassword" nzRequired>Confirm Password</nz-form-label>
      <nz-form-control [nzErrorTip]="errorTpl" [nzSm]="14" [nzXs]="24">
        <input formControlName="checkPassword" id="checkPassword" nz-input type="password"/>
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please confirm your password!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            Two passwords that you enter is inconsistent!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="phoneNumber" nzRequired>Phone Number</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzValidateStatus]="validateForm.controls['msisdn']"
        [nzXs]="24"
        nzErrorTip="Please input phone number!"
        phoneNumberPrefix
      >
        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
          <ng-template #addOnBeforeTemplate>
            +260
          </ng-template>
          <input formControlName="msisdn" id="'phoneNumber'" maxlength="9" minlength="9" nz-input/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <!-- <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="address">Address</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input you address">
        <input nz-input formControlName="address" id="address"/>
      </nz-form-control>
    </nz-form-item> -->

    <nz-form-item class="register-area" nz-row>
      <nz-form-control [nzOffset]="6" [nzSpan]="14" nzErrorTip="Please accept terms and conditions to proceed!">
        <label formControlName="agree" nz-checkbox>
          <span>I have read the <a routerLink="/terms-of-use">agreement</a></span>
        </label>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-modal>

<!-- End Pricing Section -->
