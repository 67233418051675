import {Component, OnInit} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-developers',
  templateUrl: './developers.component.html',
  styleUrls: ['./developers.component.scss']
})
export class DevelopersComponent implements OnInit {


  userId: string;
  key: string;
  smsurl: string;
  userItems = this.getFromLocalStrorage()
  requestList = [
    '1. Request body must be of type XML',
    '2. MSISDN must have "260" should appended to it',
    '3. Ensure request type is of type "Message"',
    '4. Message should be 150 characters'
  ];
  responseList = [
    '1. Response of type XML',
    '2. Response contains response code and response description'
  ]
  requestParameters = [
    {
      parameter: 'REQUESTID',
      description: 'Unique Identifier of the request being',
      sample: '1588858207',
    },
    {
      parameter: 'REQUESTTYPE',
      description: 'Type of request being sent',
      sample: 'message',
    },
    {
      parameter: 'DATE',
      description: 'Date request being sent.',
      sample: '07-05-2020 15:37:36',
    },
    {
      parameter: 'USERID',
      description: 'UserId assigned to Tumani account on sign up',
      sample: this.userItems.userId,
    },
    {
      parameter: 'KEY',
      description: 'Unique Key provided to consume API',
      sample: this.userItems.key,
    },
    {
      parameter: 'ORIGINATORID',
      description: 'Originator Address',
      sample: 'tumanisms'
    },
    {
      parameter: 'MSISDN',
      description: 'Recipient phone number',
      sample: '260XXXXXXXXX'
    },
    {
      parameter: 'TEXT',
      description: 'Message to recipient',
      sample: 'Hello World'
    }
  ]
  responseTableData = [
    {
      responseCode: '00',
      responseDescription: 'SUCCESSFUL',
      responseDetails: 'Message has been sent successfully'
    },
    {
      responseCode: '01',
      responseDescription: 'MESSAGE SENDING FAILED',
      responseDetails: 'An error occurred when sending message'
    },
    {
      responseCode: '20',
      responseDescription: 'INSUFFICIENT CREDIT',
      responseDetails: 'You have depleted your SMSs, balance now zero.'
    },
    {
      responseCode: '29',
      responseDescription: 'USER NOT REGISTERED',
      responseDetails: 'User id submitted in request is not valid'
    },
    {
      responseCode: '30',
      responseDescription: 'USER HAS NO ACCOUNT',
      responseDetails: 'User id submitted in request has no account'
    },
    {
      responseCode: '88',
      responseDescription: 'MISSING PARAMETERS',
      responseDetails: 'Request submitted has missing parameters'
    },
    {
      responseCode: '89',
      responseDescription: 'INVALID RECIPIENT PHONE NUMBER',
      responseDetails: 'Recipient phone number entered is not valid'
    },
    {
      responseCode: '149',
      responseDescription: 'ACCOUNT IS SUSPENDED',
      responseDetails: 'Your account has been suspended kindly contact customer care to rectify the issue'
    },

  ];

  constructor(public msg: NzMessageService) {
  }

  ngOnInit() {
    this.smsurl = "http://41.175.8.68:8181/bulksms/sms/index.php"
    this.userId = this.userItems.userId
    this.key = this.userItems.key
  }

  public getFromLocalStrorage() {
    const users = JSON.parse(localStorage.getItem('currentUser'));

    return users;
  }


}
