import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UsersDTO} from '../models/user.dto';
import {UserService} from '../services/user.service';
import {NzMessageService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  isVisible = false;
  isOkLoading = false;
  validateForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private readonly usersService: UserService,
    private msg: NzMessageService,
    private readonly router: Router,
    private readonly authService: AuthenticationService,
  ) {
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.validateForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  };

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      msisdn: [null, [Validators.required]],
      address: [null, [Validators.required]],
      agree: [false]
    });
  }


  showModal(): void {
    this.isVisible = true;
  }

  handleOk(data: UsersDTO): void {

    data.msisdn = "260" + data.msisdn

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      this.isOkLoading = true;
      console.log(data)
      setTimeout(() => {

        this.usersService.createUser(data).subscribe(res => {
          console.log(res)
          if (res === 200) {
            this.msg.success('Registered Successfully.')
            this.isVisible = false;
            this.isOkLoading = false;
            this.authService.logout();
            this.router.navigateByUrl('/dashboard');
          } else {
            this.msg.error('Failed to create account')
            this.isOkLoading = false;
          }
        })

      }, 5000);

    }

  }

  login() {
    this.router.navigateByUrl('/login');
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
