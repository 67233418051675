import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../services/user.service';
import {NzMessageService} from 'ng-zorro-antd';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Component({
  selector: 'app-new-pasword',
  templateUrl: './new-pasword.component.html',
  styleUrls: ['./new-pasword.component.scss']
})
export class NewPaswordComponent implements OnInit {


  isVisible = false;
  isOkLoading = false;
  newPasswordForm: FormGroup;
  isLoggingIn = false;
  showError = false;
  uuid: string;

  constructor(
    private fb: FormBuilder,
    private readonly usersService: UserService,
    private msg: NzMessageService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly authService: AuthenticationService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.uuid = params.id;
    })
    this.newPasswordForm = this.fb.group({
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
    });
  }

  newPassword(payload): void {
    for (const i in this.newPasswordForm.controls) {
      this.newPasswordForm.controls[i].markAsDirty();
      this.newPasswordForm.controls[i].updateValueAndValidity();
    }

    if (!this.newPasswordForm.invalid) {
      this.isLoggingIn = true;
      this.authService.updateUserPassword(this.uuid, payload.password).subscribe(res => {
        console.log(res);
        if (res === 200) {
          this.msg.success('Password Created Successfully.');
          this.router.navigateByUrl('/login');
        } else {
          this.msg.error('Failed to Reset Password');
          this.isLoggingIn = false;
        }

      })
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.newPasswordForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.newPasswordForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  };

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }


}
