import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {User} from 'src/app/models/users';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  userItems: any;
  userDtailes: User;
  name: string;
  email: string;
  msisdn: string;
  address: string;
  userId: string;
  key: string;


  constructor(private readonly userService: UserService) {
  }

  ngOnInit() {
    this.userItems = this.getFromLocalStrorage();

    this.userService.getUserDetails(this.userItems.id).subscribe(res => {
      console.log('User Details', res)
      this.userDtailes = res[0];
      this.name = this.userDtailes.name;
      this.email = this.userDtailes.email;
      this.msisdn = this.userDtailes.msisdn;
      this.address = this.userDtailes.address;
      this.key = this.userDtailes.key;
      this.userId = this.userDtailes.userId;
    })
  }

  public getFromLocalStrorage() {
    const users = JSON.parse(localStorage.getItem('currentUser'));

    return users;
  }

}
