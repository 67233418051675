import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/users';
import {UsersDTO} from '../models/user.dto';
import {Message} from './../models/messages.model';
import {BaseUrlService} from "./baseUrl.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
  private readonly baseUrlService: BaseUrlService

) {
  }

  createUser(user: UsersDTO) {
    return this.http.post<any>(`${this.baseUrlService.BASE_URL}/auth/register`, user);
  }

  getUserDetails(userId: number) {
    return this.http.get<User>(`${this.baseUrlService.BASE_URL}/users/${userId}`);
  }

  getAccountBalance(userId: number) {
    return this.http.get(`${this.baseUrlService.BASE_URL}/users/account-balance/${userId}`);
  }

  getTotalMessageCount(userId: any) {
    return this.http.get<number>(`${this.baseUrlService.BASE_URL}/users/all-message-count/${userId}`)
  }

  getSuccessMessageCount(userId: number) {
    return this.http.get<number>(`${this.baseUrlService.BASE_URL}/users/successful-message-count/${userId}`)
  }

  getFailedMessageCount(userId: number) {
    return this.http.get<number>(`${this.baseUrlService.BASE_URL}/users/failed-message-count/${userId}`)
  }

  getSuccessfulMessage(userId: any) {
    return this.http.get<Message>(`${this.baseUrlService.BASE_URL}/users/failed-message-count/${userId}`)
  }

  getFailedMessage(userId: any) {
    return this.http.get<Message>(`${this.baseUrlService.BASE_URL}/users/failed-message-count/${userId}`)
  }
}
