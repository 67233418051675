import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SendQuickSMSDTO} from '../models/quickSms.dto';
import {ImportSmsDto} from '../models/importSms.dto';
import {BaseUrlService} from "./baseUrl.service";


@Injectable({
  providedIn: 'root'
})
export class SendSmsService {

  constructor(
    private http: HttpClient,
    private readonly baseUrlService: BaseUrlService
  ) {
  }

  sendMessage(sms: SendQuickSMSDTO): any {
    return this.http.post<SendQuickSMSDTO>(`${this.baseUrlService.BASE_URL}/send-sms`, sms);
  }

  sendGroupMessages(data: any): any {
    return this.http.post(`${this.baseUrlService.BASE_URL}/send-sms/group-sms`, data);
  }

  sendBroadcastMessage(payload: any) {
    return this.http.post(`${this.baseUrlService.BASE_URL}/send-sms/broadcast-sms`, payload);
  }

  sendImportedMessages(importSmsPayload: ImportSmsDto) {
    console.log("MY PAYLOAD", importSmsPayload);
    return this.http.post<string>(`${this.baseUrlService.BASE_URL}/send-sms/import-sms`, importSmsPayload);
  }
}
