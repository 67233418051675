<div class="Container">
  <nz-tabset (nzSelectedIndexChange)="tabChange($event)">
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="titleTemplate">
      <ng-template #titleTemplate>{{ tab.name }} <i [nzTheme]="tab.theme" [nzTwotoneColor]="tab.color" [nzType]="tab.icon"
                                                    nz-icon></i></ng-template>

      <!-- All messages Table  -->
      <div class="tableContainer">
        <nz-table nzSize="small" #basicTable *ngIf="isAllMessagesVisable" [nzData]="listOfAllMessagesData">
          <thead>
          <tr>
            <th><p style="font-weight: bold;"> Reciepent </p></th>
            <th><p style="font-weight: bold;"> Originator </p></th>
            <th><p style="font-weight: bold;"> Message </p></th>
            <th><p style="font-weight: bold;"> Status </p></th>
            <th><p style="font-weight: bold;"> Date created</p></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.msisdn }}</td>
            <td>{{ data.originator }}</td>
            <td>{{ data.message }}</td>
            <td>
              {{ data.status }}
            </td>
            <td>{{ data.created_on }}</td>
          </tr>
          </tbody>
        </nz-table>
      </div>

      <!-- Sent Messages Table -->
      <div class="tableContainer">
        <nz-table nzSize="small" #basicTable *ngIf="isSentMessagesVisable" [nzData]="listOfSentMessagesData">
          <!-- <nz-table *ngIf="isSentMessagesVisable" #basicTable [nzData]="listOfFailedMessagesData"> -->
          <thead>
          <tr>
            <th><p style="font-weight: bold;"> Reciepent </p></th>
            <th><p style="font-weight: bold;"> Originator </p></th>
            <th><p style="font-weight: bold;"> Message </p></th>
            <th><p style="font-weight: bold;"> Status </p></th>
            <th><p style="font-weight: bold;"> Date created</p></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.msisdn }}</td>
            <td>{{ data.originator }}</td>
            <td>{{ data.message }}</td>
            <td>{{ data.status }}</td>
            <td>{{ data.created_on }}</td>
          </tr>
          </tbody>
        </nz-table>
      </div>

      <!-- Failed Messages Table -->
      <div class="tableContainer">
        <nz-table nzSize="small" #basicTable *ngIf="isFailedMessagesVisable" [nzData]="listOfFailedMessagesData">
          <!-- <nz-table *ngIf="isFailedMessagesVisable" #basicTable [nzData]="listOfSentMessagesData"> -->

          <thead>
          <tr>
            <th><p style="font-weight: bold;"> Reciepent </p></th>
            <th><p style="font-weight: bold;"> Originator </p></th>
            <th><p style="font-weight: bold;"> Message </p></th>
            <th><p style="font-weight: bold;"> Status </p></th>
            <th><p style="font-weight: bold;"> Date created</p></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.msisdn }}</td>
            <td>{{ data.originator }}</td>
            <td>{{ data.message }}</td>
            <td>{{ data.status }}</td>
            <td>{{ data.created_on }}</td>
          </tr>
          </tbody>
        </nz-table>
      </div>

    </nz-tab>
  </nz-tabset>
</div>
