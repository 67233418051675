import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContactDTO} from '../models/contacts.dto';
import {Contact} from '../models/contacts.model';
import {BaseUrlService} from "./baseUrl.service";



@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(
    private http: HttpClient,
    private readonly baseUrlService: BaseUrlService
              ) {
  }

  addContact(userId: number, contact: ContactDTO): Observable<ContactDTO> {
    return this.http.post<ContactDTO>(`${this.baseUrlService.BASE_URL}/users/contacts/${userId}`, contact);
  }

  addMultipleContact(userId: number, bulkContact: any) {
    console.log('USER ID', userId)
    console.log('Bulk Contact', bulkContact);
    console.log(bulkContact);
    return this.http.post(`${this.baseUrlService.BASE_URL}/users/bulk-contacts/${userId}`, bulkContact);

  }

  addContactwithGroup(userId: number, groupId: number, contact: ContactDTO): Observable<ContactDTO> {
    return this.http.post<ContactDTO>(`${this.baseUrlService.BASE_URL}/users/contacts/${userId}/${groupId}`, contact);
  }

  getContacts(userId: number) {
    return this.http.get<Contact[]>(`${this.baseUrlService.BASE_URL}/users/contacts/${userId}`);
  }

  getGroupContacts(userId: number, groupId: number) {
    return this.http.get<Contact[]>(`${this.baseUrlService.BASE_URL}/users/group/contacts/${userId}/${groupId}`);

  }

  deleteContact(contacts: any) {
    console.log(contacts.id);
    return this.http.delete(`${this.baseUrlService.BASE_URL}/users/delete-contact/${contacts.id}`);
  }

  updateContact(contactId: number, contact: Contact) {
    console.log("RECIEVED CONTACT UPDATE PAYLOAD====>", contact);
    console.log(contactId);
    return this.http.patch(`${this.baseUrlService.BASE_URL}/contacts/${contactId}`, contact);
  }

  getContactCount(userId: number) {
    return this.http.get(`${this.baseUrlService.BASE_URL}/users/contact-count/${userId}`);
  }

}
