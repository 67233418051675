import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-email-sent-message',
  templateUrl: './email-sent-message.component.html',
  styleUrls: ['./email-sent-message.component.scss']
})
export class EmailSentMessageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
