<!-- ======= Contact Section ======= -->
<section class="contact" id="contact">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Contact Us</h2>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
        <div class="contact-about">
          <h3>Tumani</h3>
          <p>Contact Hobbiton Tech or visit us at our office for more information on Tumani. We are here to connect to
            you to your clients</p>
          <!-- <div class="social-links">
            <a href="#" class="twitter"><i class="icofont-twitter"></i></a>
            <a href="#" class="facebook"><i class="icofont-facebook"></i></a>
            <a href="#" class="instagram"><i class="icofont-instagram"></i></a>
            <a href="#" class="linkedin"><i class="icofont-linkedin"></i></a>
          </div> -->
        </div>
      </div>

      <div class="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
        <div class="info">
          <div>
            <i class="ri-map-pin-line"></i>
            <p>Rhodes Park, Lagos Road<br>
              Zanera Office Park<br>
              Lusaka, Zambia</p>
          </div>
          <div>
            <i class="ri-mail-send-line"></i>
            <p>customercare@tumanisms.com</p>
          </div>

          <div>
            <i class="ri-phone-line"></i>
            <p>+260973548906 / +260977872726</p>
          </div>

        </div>
      </div>

      <div class="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
        <form action="forms/contact.php" class="php-email-form" method="post" role="form">
          <div class="form-group">
            <input class="form-control" data-msg="Please enter at least 4 chars" data-rule="minlen:4" id="name" name="name" placeholder="Your Name"
                   type="text"/>
            <div class="validate"></div>
          </div>
          <div class="form-group">
            <input class="form-control" data-msg="Please enter a valid email" data-rule="email" id="email" name="email" placeholder="Your Email"
                   type="email"/>
            <div class="validate"></div>
          </div>
          <div class="form-group">
            <input class="form-control" data-msg="Please enter at least 8 chars of subject" data-rule="minlen:4" id="subject" name="subject"
                   placeholder="Subject" type="text"/>
            <div class="validate"></div>
          </div>
          <div class="form-group">
            <textarea class="form-control" data-msg="Please write something for us" data-rule="required" name="message"
                      placeholder="Message" rows="5"></textarea>
            <div class="validate"></div>
          </div>
          <div class="mb-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div>
          <div class="text-center">
            <button type="submit">Send Message</button>
          </div>
        </form>
      </div>

    </div>

  </div>
</section><!-- End Contact Section -->
