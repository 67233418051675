import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {NzMessageService} from 'ng-zorro-antd';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  validateStatus: string;
  loginForm: FormGroup;

  isLoggingIn = false;
  showError = false;


  constructor(
    private fb: FormBuilder,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private message: NzMessageService
  ) {
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      remember: [true]
    });
  }

  async login(credentials): Promise<void> {

    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
    if (!this.loginForm.invalid) {
      this.isLoggingIn = true;
      this.authenticationService.login(credentials.email, credentials.password).subscribe(res => {

        if (res.status === 200) {
          this.router.navigateByUrl('/dashboard');


        } else if (res.status === 404) {
          this.isLoggingIn = false;
          this.message.create('error', `${res.response}`, {
            nzDuration: 10000
          });
        } else if (res.status === 400) {
          this.isLoggingIn = false;
          this.message.create('error', `${res.response}`, {
            nzDuration: 10000
          });
        } else {
          this.isLoggingIn = false;
          this.message.create('error', 'Sorry An Error Occured', {
            nzDuration: 10000
          });
        }

      });

      // setTimeout(() => {
      //   this.isLoggingIn = false;
      // }, 5000);

    }
  }

}
