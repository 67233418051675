<nz-divider nzOrientation="left" nzText="My Account"></nz-divider>

<nz-row nzJustify="space-around" nzType="flex">
  <div nz-col nzLg="8" nzMd="6" nzSm="4" nzXl="10" nzXs="2">
    <h3
      style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
      Your Api credentials
    </h3>
    <nz-card
      style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
      <p>These credentials will be used to aunthenticate your request everytime you send a mesage.</p>
      <p>Do not share your api credentials with anyone.</p>
      <img alt="content"
           src="assets/security.png"
           style="width: 200px;"
      />
      <nz-collapse nzAccordion>
        <nz-collapse-panel [nzActive]="false" [nzHeader]="'Click to View Your Api credentials'">
          <div style="font-size: 15px; font-weight:bold;">
                      <span>
                          UserId:
                      </span>
            <span>{{ userId }}</span>
          </div>
          <div>
            <span style="font-size: 15px; font-weight:bold;">Key:</span> <span>{{ key }}</span>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-card>
  </div>

  <div nz-col nzLg="8" nzMd="6" nzSm="4" nzXl="10" nzXs="2">
    <h3
      style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
      Your Details
    </h3>
    <nz-card>
      <nz-descriptions [nzColumn]="1" nzBordered nzTitle="User Info">
        <nz-descriptions-item nzTitle="Name">{{ name }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Email">{{ email }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Phone Number">{{ msisdn }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Address">{{ address }}</nz-descriptions-item>
      </nz-descriptions>
    </nz-card>
  </div>
</nz-row>

<nz-row style="padding: 25px;">
  <nz-card>

  </nz-card>
</nz-row>

<nz-row style="padding: 25px;">
    <nz-card>

    </nz-card>
</nz-row>
