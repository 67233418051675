<!-- ======= About Us Section ======= -->
<section class="about" id="about">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>About Us</h2>
    </div>

    <div class="row content">
      <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
        <p>
          Hobbiton Technologies is a Zambian owned dynamic Fintech Company with a strong focus on digital savings,
          investments, trading , payment aggregation and communication platforms.
          Hobbiton has a number of products in the market serving a cross section of the economy and sectors.Hobbiton
          decided to develop the TumaniSMS Platform to help businesses better communicate with their clients via SMS and
          other messaging solutions.
          The TumaniSMS platform can be integrated with Third Party platforms or clients can send SMS via a web
          interface.
        </p>

        <a class="btn-learn-more" href="#">Learn More</a>
        <!-- <ul>
          <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
          <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
          <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
        </ul> -->
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="300">
        <h4>VISION</h4>
        <p>
          To be a world class provider of technology driven financial services.
        </p>
        <h4>MISSION</h4>
        <p>
          To exceed people's financial security and access needs through technology
        </p>
        <h4>VALUES</h4>
        <ol>
          <li>Excellence – We shall be outstanding and deliver quality it all we do.</li>
          <li>Integrity – We shall be honest in our dealings</li>
          <li>Reliability – We shall be consistent , available and trustworthy in our dealings</li>
          <li>Accountability – We shall be responsible and take ownership in our dealings.</li>
          <li>Teamwork- Together, we achieve more.</li>
        </ol>


      </div>
    </div>

  </div>
</section><!-- End About Us Section -->
