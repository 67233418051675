import {Component, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    title: {
      text: 'Monthly Usage'
    },
    subtitle: {
      text: 'Average Usage'
    },
    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    series: [{
      data: [1, 4, 2, 5, 2.5, 7, 3, 4, 1, 6, 3, 6],
      type: 'spline'
    }]
  };

  constructor() {
  }

  ngOnInit() {
  }

}
