import {Component, OnInit} from '@angular/core';
import {MessagesService} from './../services/messages.service';
import {UserService} from './../services/user.service';
import {Message} from '../models/messages.model';

@Component({
  selector: 'app-sms-over-view',
  templateUrl: './sms-over-view.component.html',
  styleUrls: ['./sms-over-view.component.scss']
})
export class SmsOverViewComponent implements OnInit {

  isAllMessagesVisable = true;
  isSentMessagesVisable = false;
  isFailedMessagesVisable = false;
  listOfAllMessagesData: Message[] = [];
  listOfFailedMessagesData: Message[] = [];
  listOfSentMessagesData: Message[] = [];
  userItems: any;

  tabs = [
    {
      active: true,
      name: 'All Messages',
      icon: 'history',
      theme: '',
      color: '#52c41a',
    },
    {
      active: false,
      name: 'Sent Messages',
      icon: 'check-circle',
      theme: 'twotone',
      color: '#52c41a',
    },
    {
      active: false,
      name: 'Failed Messages',
      icon: 'exclamation-circle',
      theme: 'twotone',
      color: '#FF0000',
    },
  ];


  tabChange(evt) {
    if (evt === 0) {
      this.isAllMessagesVisable = true;
      this.isSentMessagesVisable = false;
      this.isFailedMessagesVisable = false;
    } else if (evt === 1) {
      this.isAllMessagesVisable = false;
      this.isSentMessagesVisable = true;
      this.isFailedMessagesVisable = false;
    } else if (evt === 2) {
      this.isAllMessagesVisable = false;
      this.isSentMessagesVisable = false;
      this.isFailedMessagesVisable = true;
    }
  }

  constructor(
    private readonly messageService: MessagesService,
    private readonly userService: UserService
  ) {
  }

  ngOnInit() {
    this.userItems = this.getFromLocalStrorage();
    const userId = this.userItems.userId;
    console.log('userId ==> ' + userId)
    const messageSuccessStatus = "SUCCESS";
    const failedMessageStatus = "FAILED";

    this.messageService.getAllMessages(userId).subscribe((data) => {
      console.log(data);
      this.listOfAllMessagesData = data;
    })

    this.messageService.getMessageBySuccessStatus(userId).subscribe((data) => {
      console.log(data);
      this.listOfSentMessagesData = data
    })

    this.messageService.getMessageByFailedStatus(userId).subscribe((data) => {
      console.log(data)
      this.listOfFailedMessagesData = data
    })

  }

  public getFromLocalStrorage() {
    const users = JSON.parse(localStorage.getItem('currentUser'));
    console.log('============');
    console.log(users);
    return users;
  }


}
