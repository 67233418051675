import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SmsPaymentService} from '../services/sms-payment.service';
import {NzMessageService, NzNotificationComponent, NzNotificationService} from 'ng-zorro-antd';
@Component({
  selector: 'app-dashboard',
  template: `

    <nz-spin [nzSpinning]="isLoggingOut">

      <nz-layout>
        <nz-header style="position: fixed; width: 100%; z-index: 999;">
          <div class="app-header" nz-row nzJustify="space-between" nzType="flex">
            <div nz-col>
              <div style="margin-left: 30px;">
          <span
            style="font-size: 25px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
            <img alt="content"
                 src="assets/logo.jpeg"
                 style="width: 70px;"
            />
          </span
          >
              </div>
            </div>
            <div nz-col>
              <div nz-row>
                <!-- <a href="#" style="margin-right: 15px;">Support</a>
                <a href="#" style="margin-right: 15px;">Pricing</a> -->
                <nz-divider nzType="vertical"></nz-divider>
                <sup>BALANCE:</sup>
                <span
                  style="margin-right: 20px; font-weight: bold; font-size: 20px;"> {{ balance ? balance : '0.0' }}</span>
                <button (click)="topUp()" nz-button nzType="primary">Top Up</button>
                <nz-divider nzType="vertical"></nz-divider>
                <span
                  style="margin-right: 40px; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
            <!-- <nz-avatar [nzSize]="30" [nzIcon]="'user'"></nz-avatar> -->
            <a [nzDropdownMenu]="menu" nz-dropdown>
              {{ email }}
              <i nz-icon nzType="down"></i>
            </a>
          </span>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item>billing</li>
                    <li nz-menu-divider></li>
                    <li nz-menu-item>Settings</li>
                    <li nz-menu-divider></li>
                    <li nz-menu-item routerLink="./my-account">Your Account</li>
                    <li nz-menu-divider></li>
                    <li (click)="logout()" nz-menu-item>Logout</li>
                  </ul>
                </nz-dropdown-menu>
              </div>
            </div>
          </div>
        </nz-header>
        <nz-layout style="height: 100vh;">
          <nz-sider
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null"
            class="menu-sidebar"
            nzCollapsible
            style="padding-top: 60px; position: fixed;"
          >
            <ul [nzInlineCollapsed]="isCollapsed" nz-menu nzTheme="light">
              <li
                [nzTitle]="isCollapsed ? 'Dashboard' : ''"
                nz-menu-item
                nz-tooltip
                nzPlacement="right"
              >
                <i nz-icon nzType="dashboard"></i>
                <a routerLink="./welcome"></a>
              </li>

              <li nz-submenu nzIcon="message" nzTitle="Message">
                <ul>
                  <li nz-menu-item>
                    <a routerLink="./sms-overview">SMS Overview</a>
                  </li>
                  <li nz-menu-item><a routerLink="./quick-send">Quick Send</a></li>
                  <li nz-menu-item><a routerLink="./bulk-sms"></a> Bulk SMS</li>
                  <li nz-menu-item>
                    <a routerLink="./personalized-sms"></a> Personalized SMS
                  </li>
                </ul>
              </li>
              <li
                [nzTitle]="isCollapsed ? 'Contacts' : ''"
                nz-menu-item
                nz-tooltip
                nzPlacement="right"
              >
                <i nz-icon nzType="team"></i>
                <a routerLink="./contacts"></a>
              </li>
              <li
                [title]="isCollapsed ? 'Developers' : ''"
                nz-menu-item
                nz-tooltip
                nzPlacement="right"
              >
                <i nz-icon nzType="code"></i>
                <a routerLink="./developers"></a>
              </li>
              <!-- <li
                nz-menu-item
                nz-tooltip
                nzPlacement="right"
                [nzTitle]="isCollapsed ? 'Insights' : ''"
              >
                <i nz-icon nzType="bar-chart"></i>
                <a routerLink="./insights"></a>
              </li> -->
            </ul>
          </nz-sider>
          <nz-content>
            <div style="padding-top: 50px; margin-left: 120px;">
              <router-outlet></router-outlet>
            </div>
          </nz-content>
        </nz-layout>
      </nz-layout>

    </nz-spin>

    <!-- Top Up Modal -->

    <nz-modal (nzOnCancel)="cancelTopUpModal()" [(nzVisible)]="isTopModalVisable" nzTitle="SMS Top Up" nzWidth="650">
      <!-- Pricing Containers -->
      <div>
        <nz-divider nzOrientation="left" nzText="SMS Pricing"></nz-divider>
        <div style="text-align: center;">
          <nz-table nzShowPagination="false" nzScroll="false" #smallTable [nzData]="priceList" nzSize="small"
                    style="text-align: center;">
            <thead>
            <tr>
              <th>1 to 50,000 SMSs</th>
              <th>50,000 to 100,000</th>
              <th>100,000 plus</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of smallTable.data">
              <td>ZMW {{ data.minimumBundle }}</td>
              <td>ZMW {{ data.middleBundle }}</td>
              <td>ZMW {{ data.highestBundle }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
        <div style="padding: 10px; text-align: center;">
          <span><p>Amount Payable:</p></span>
          <h3>ZMW {{ this.smsAmount.toFixed(2) }}</h3>
        </div>
        <nz-divider nzOrientation="left" nzText="Mobile Payments"></nz-divider>

        <div style="text-align: center;">
          <img src="../../assets/airtel_logo.png" alt="Image 1" style="width: 10.33%; margin-right: 15px;">
          <img src="../../assets/mtn_logo.png" alt="Image 2" style="width: 10.33%; margin: 15px;">
          <img src="../../assets/zamtel_logo.png" alt="Image 3" style="width: 10.33%; margin-left: 15px;">

        </div>
          <div>

            <nz-input-group>

              <label>Enter Number of SMSs</label>
              <input (ngModelChange)="calculateAmountPayable($event)" [(ngModel)]="amount"
                     onkeyup="if (this.value > 20000) return false;"
                     nz-input placeholder="Number of SMS's"
                     class="rounded-l-none"
                     maxLength="5"
                     type="text"/>
            </nz-input-group>

<!--            <i *ngIf="this.amountEnterIsValid" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"-->
<!--               [nzType]="'check-circle'"-->
<!--               nz-icon></i>-->
            <i *ngIf="this.amountHasError" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
               [nzType]="'exclamation-circle'"
               nz-icon></i>
          </div>
          <div>
            <label>Billing Phone Number</label>
            <nz-input-group nzAddOnBefore="260">
              <input (ngModelChange)="mobileNumberInput($event)" [(ngModel)]="mobileInput" nz-input
                     placeholder="Phone Number"
                     maxLength="9"
                     type="text"/>
            </nz-input-group>
<!--            <i *ngIf="this.mobileNumberIsCorrect" [nzTwotoneColor]="'#52c41a'" nz-icon nzTheme="twotone"-->
<!--               nzType="check-circle"></i>-->
            <i *ngIf="this.mobileNumberHasError" [nzTheme]="'twotone'" [nzTwotoneColor]="'#FF0000'"
               [nzType]="'exclamation-circle'"
               nz-icon></i>
          </div>
        </div>

      <div *nzModalFooter>
        <button (click)="cancelTopUpModal()" nz-button nzType="default">Discard</button>
        <button
          (click)="topUpModalOk()" [disabled]="confirmNumber()"
          [nzLoading]="isConfirmTopUpLoading"
          nz-button
          nzType="primary"
        >Process payment
        </button>
      </div>


    </nz-modal>


  `,
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isCollapsed = true;
  userItems: any;
  email: string;
  isLoggingOut = false;
  account: any;
  balance: number;
  isTopModalVisable = false;
  isConfirmTopUpLoading = false;
  isAirtelPaymentVisiable = true;
  mobileInput = null;
  amount = null;
  validateForm!: FormGroup;
  mobileNumberIsCorrect = false;
  amountEnterIsValid = false
  amountHasError = false;
  mobileNumberHasError = false;
  smsAmount: number = 0;
  paymentPayload: any;


  priceList = [
    {
      minimumBundle: '0.20',
      middleBundle: 0.18,
      highestBundle: 0.16
    }
  ]


  constructor(
    private readonly notification: NzNotificationService,
    private readonly smsPaymentService: SmsPaymentService,
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.validateForm = this.fb.group({})
    this.userItems = this.getFromLocalStrorage();
    this.email = this.userItems.email;


    this.userService.getAccountBalance(this.userItems.userId).subscribe(res => {

      this.account = res
      this.balance = this.account.balance;
      console.log(this.account.balance);
    });
  }

  public getFromLocalStrorage() {
    const users = JSON.parse(localStorage.getItem('currentUser'));
    return users;
  }

  logout() {
    this.isLoggingOut = true;
    setTimeout(() => {
      this.authService.logout();
      this.router.navigateByUrl('/login')
      this.isLoggingOut = false;
    }, 5000);
  }

  topUp() {
    this.isTopModalVisable = true;
  }

  cancelTopUpModal() {
    this.isTopModalVisable = false;
    this.smsAmount = 0;
  }

  topUpModalOk() {
    this.isConfirmTopUpLoading = true;
    this.notification.info("Enter your Pin",
  "You will receive a notification once your transaction has been successfully processed.")
    setTimeout(() => {
      this.isTopModalVisable = false;
      this.isConfirmTopUpLoading = false;
    }, 3000);

    this.paymentPayload = JSON.stringify({
      userId: this.userItems.userId,
      amount: this.smsAmount,
      msisdn: '260' + this.mobileInput,
      numberOfMessages: this.amount
    })



    return this.smsPaymentService.sendPaymentRequest(

      {
        accountNumber: '260' + this.mobileInput,
        amount: this.smsAmount,
        currency: "ZMW",
        email: this.userItems.email,
        externalId: "",
        fullName: this.userItems.fullName,
        narration: "payment for sms",
        phoneNumber: '260' + this.mobileInput,
        smsNumber: this.amount,
        userId: this.userItems.userId

      }
    ).subscribe(res => {
      switch (res.status) {

        case "Pending":
          this.isTopModalVisable = false;
          this.mobileInput =''
          this.amount =''
          this.smsAmount =0
          this.notification.success(
            "Enter Your Pin",
            "Enter your pin to complete the transaction " +
            "You will receive a notification once your transaction has been successfully processed. "
          );
          break;
        case "Successful":
          this.mobileInput =''
          this.amount =''
          this.smsAmount =0
          this.notification.success("Successful", "You have Successful Paid");
          break;
        case "Failed":
          this.notification.error("Failed","Transaction failed please try again ")
          break;
        default:
          this.notification.error("Failed","Something went Wrong ")
          break;
      }
    })
  }

  calculateAmountPayable(numberOfSms) {

    if (!isNaN(numberOfSms)) {
      this.amountEnterIsValid = true;
      this.amountHasError = false;
      this.amount = numberOfSms

      if (numberOfSms < 50000) {
        this.smsAmount = numberOfSms * 0.20
      } else if (numberOfSms >50000 && numberOfSms < 100000) {
        this.smsAmount = numberOfSms * 0.18
      } else if (numberOfSms > 100000) {
        this.smsAmount = numberOfSms * 0.16
      }
    } else {

      this.amountEnterIsValid = false;
      this.amountHasError = true;

    }
  }

  mobileNumberInput(number) {

    if (!isNaN(number)) {

      if (number.charAt(1) == 5||6||7 && number.length == 9) {

        this.mobileNumberIsCorrect = true;
        this.mobileNumberHasError = false;
        this.mobileInput = number
      }else {
        this.mobileNumberIsCorrect = false;
        this.mobileNumberHasError = true;
      }
    }
  }

  confirmNumber() {
    if (this.amountEnterIsValid == true && this.mobileNumberIsCorrect == true) {
      return false
    } else {
      return true
    }
  }


}
