<!-- ======= Developers Section ======= -->
<section class="heros" id="heros" style="padding-top: 100px;">
  <div class="container">

    <div class="section-title" data-aos="fade-up">
      <h2>Terms Of Use</h2>
    </div>

    <div class="row">
      <h5>1. Indroduction and Definitions</h5>
      <p>
        Welcome to the Hobbiton Services, which you may be accessing through a Website,
        Application, or other means. We are confident that you will find our Services useful. These
        Terms of Use (the “TOU”) cover several different situations, so please refer to the following
        definitions for clarification:
        “Application” refers to a free app for iOS or Android created and owned by Hobbiton.
        Application also includes any Application updates and upgrades that Hobbiton may
        provide to you or make available to you from time to time, or that you obtain after the date
        you obtain your initial copy of the Application, to the extent that such items are not
        accompanied by a separate Terms of Use. The Application may be specified as either “iOS
        Application” or “Android Application”, for use in that Application’s operating system
        environment.
        “Business Premises” refers to the property which is covered when a Logged-in User uses
        the Services to purchase House Insurance, which may be more fully defined in an Insurance
        Policy for House Insurance.
        “Device” refers to the handheld or tablet device onto which you downloaded an
        Application and are using the Services, or are otherwise accessing the Services. The Device
        may be specified as either an “iOS Device” or “Android Device”.
        In these TOU, a Website visitor or Digital Platform user may be referred to as “you”. When a
        user has created an account and is logged in, they may be referred to as a “Logged-in User”.
        Additional definitions may also be found in the various Insurance Policy wordings (as may
        be applicable), and will have the same meaning in these TOU as they do there. In the event
        that there is a discrepancy between the definitions as they are found in these TOU and in the
        individual policy wording, please note that the definitions in the individual policy wording
        shall override those in these TOU.
      </p>

      <h5>2. Acceptance and Modifications</h5>
      <p>
        By visiting the Website, using the Services on the Website, or downloading, using or
        accessing any of the Hobbiton digital platforms on your Device, or by using the Services in
        any way, you hereby accept to be bound by these TOU without any reservations,
        modifications, additions or deletions. Upon your creation of an account, you would also
        have clicked or tapped an acceptance box, indicating your agreement to be bound by the
        TOU.</p>


      <p>
        If you are an individual using the Website or Services on behalf of a corporation or other
        registered organization (“Legal Entity”), you represent and warrant that you have the
        capacity and authority to be bound by these TOU on behalf of the Legal Entity which you
        represent, and hereby agree to be bound by all the terms herein on behalf of that Legal
        Entity.
        If you do not agree to all the provisions contained in the TOU, you are not authorized to
        use the Website or Services.
      </p>
      <p>
        Hobbiton reserves the right to modify or replace any of the TOU contained herein.. If there
        are any material changes to these TOU, you will be notified upon logging-in to the Services
        or via email.
      </p>
      <p>
        Your use of the Website and / or the Hobbiton digital platforms following your being
        notified of any changes to the TOU constitutes acceptance of those changes.
      </p>
      <p>
        The TOU should be read in conjunction with the Insurance Policy (as applicable), as all these
        documents govern your use of the Website and / or Services.
      </p>
      <div>
        <h5>3. Contacting Us </h5>
        <p>
          If you have any questions about the TOU, please contact:
          Hobbiton Technologies Limited
          Zanera Office Park
          Lagos Road
          Lusaka
        </p>
      </div>


      <h5>
        4. General Code of Conduct for Use of the Website and Digital Platforms
      </h5>
      <p>
        By visiting the Website and the Hobbiton digital platforms , you agree to:
      </p>
      <p>
        A. Not use the Website or Digital Platforms in any manner that in any way violates these
        TOU;
      </p>
      <p>
        B. Not use the Website or Digital Platforms in any manner that violates any intellectual
        property rights of Hobbiton or any third party, including but not limited to the Third-Party
        Insurer (if applicable);
      </p>
      <p>
        C. Not use the Website or Digital Platforms in any manner to propagate spam, including but
        not limited to unsolicited advertising or bulk electronic mail or messages, including to link
        to a spam or phishing website;
      </p>
      <p>
        D. Not use the Website or Digital Platforms in any manner to propagate software viruses,
        Trojan horses, worms, or any other malicious or non-malicious computer code, files, or
        programs that are designed or intended to disrupt, damage, limit or interfere with the
        proper function of any software, hardware, or telecommunications equipment in any form
        whether belonging to Hobbiton or a third party, or to damage or obtain unauthorized access
        to any system, data, or other information (whether Personal Information or not) of
        Hobbiton, other Digital Platform users, or any other third party;
      </p>
      <p>
        E. Not: (1) take any action that imposes or may impose (as determined by Hobbiton in its
        sole discretion) an unreasonable or disproportionately large load on Hobbiton’s (or its third
        party providers’) infrastructures; (2) interfere or attempt to interfere with the proper
        functioning of the Website or Services or any activities conducted on the Website or through
        the Services; (3) bypass any measures Hobbiton may use to prevent or restrict access to the
        Website or Services or any element thereof; (4) use manual or automated software, devices,
        or other processes to “scrape”, “crawl” or “spider” any page or services of the Website or
        Application; or (5) harvest or scrape any content from the Website or Application;
      </p>
      <p>
        F. Not visit the Website if you are under the age of 13, or use the digital platforms if you are
        under the age of 21;
      </p>
      <p>
        G. Use the Website and / or Digital Platforms in good faith, and in compliance with all
        applicable local, state, national, and international laws.
      </p>

      <h5>
        5. Use of the Application
      </h5>

      <p>
        a. The Application is licensed, not sold. Hobbiton grants you a non-exclusive, non-
        transferable, non-sublicensable, limited right and license to install and use the Application
      </p>

      <p>
        solely and exclusively for your personal use on your Device, or the use of any other third
        party with access to the Device that you control and with your permission, and as permitted
        by the Usage Rules set forth in the App Store Terms and Conditions for the iOS Application
        or the Google Play Terms of Service for the Android Application.
      </p>

      <p>
        b. You may not use the Application in any manner that could damage, disable, overburden,
        or impair the Application (or servers or networks connected to the Application), nor may
        you use the Application in any manner that could interfere with any other party’s use and
        enjoyment of the Application (or servers or networks connected to the Application).
        c. You agree that you are solely responsible for (and that Hobbiton has no responsibility to
        you or to any third party for) your use of the Application, any breach of your obligations
        under the TOU, and for the consequences (including any loss or damage, direct or indirect,
        pecuniary or non-pecuniary, which Hobbiton may suffer) of any such breach. Where a third
        party uses the Application on your Device, you are solely responsible for conforming to
        these TOU and any breach thereof shall be your responsibility.
      </p>

      <h5>
        5. Use of the Application
      </h5>

      <p>
        a. The Application is licensed, not sold. Hobbiton grants you a non-exclusive, non-
        transferable, non-sublicensable, limited right and license to install and use the Application
      </p>

      <p>
        solely and exclusively for your personal use on your Device, or the use of any other third
        party with access to the Device that you control and with your permission, and as permitted
        by the Usage Rules set forth in the App Store Terms and Conditions for the iOS Application
        or the Google Play Terms of Service for the Android Application.
      </p>

      <p>
        b. You may not use the Application in any manner that could damage, disable, overburden,
        or impair the Application (or servers or networks connected to the Application), nor may
        you use the Application in any manner that could interfere with any other party’s use and
        enjoyment of the Application (or servers or networks connected to the Application).
        c. You agree that you are solely responsible for (and that Hobbiton has no responsibility to
        you or to any third party for) your use of the Application, any breach of your obligations
        under the TOU, and for the consequences (including any loss or damage, direct or indirect,
        pecuniary or non-pecuniary, which Hobbiton may suffer) of any such breach. Where a third
        party uses the Application on your Device, you are solely responsible for conforming to
        these TOU and any breach thereof shall be your responsibility.
      </p>
      <h5>
        6. Privacy Policy and Permissions for the Application
      </h5>
      <p>
        a. Our Privacy Policy explains how Hobbiton treats your Personal Information (as that term
        is defined in the Privacy Policy) and protects your privacy when you use the Website or
        Digital Platforms. By installing, accessing or using the Application or using the Website or
        Services you explicitly agree to the use of your information in accordance with the Privacy
        Policy, which may be updated from time to time and without notice.
      </p>
      <p>
        b. By installing, accessing or using the Application or using the Website or Services, you
        consent to those information collection and usage terms as described in the Privacy Policy.
        c. This paragraph describes how the Application accesses functions of your Device that are
        not part of the Application and are developed by third parties. These are known as
        “permissions”, and you agree that Hobbiton has the right to access third-party computer

        code on your Device in this manner. The following is a complete listing and description of
        what functions on the Device that were developed by third parties are accessed and / or
        modified by the Application on your Device. Where noted, these will function with explicit
        user permission only. You acknowledge that denying explicit permission may affect or
        reduce your user experience with the Services:
      </p>
      <ul>
        <li>
          Location – Allows the Application to access your current location (user permission only)
        </li>
        <li>
          Notifications – The Application may send you notifications (user permission only)
        </li>
        <li>
          Device & App History – The Application may read this log data stored on your Device
        </li>
        <li>
          Wi-Fi Connection Information – The Application may retrieve network connectivity and
          speed information from your Device
        </li>
      </ul>


    </div>

  </div>
</section><!-- End Developers Section -->
