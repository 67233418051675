<nz-spin [nzSpinning]="isLoggingIn">
  <div style="margin: 0 auto; width: 400px; height: 100%;">
    <div class="logo" style="text-align: center; margin: 70px auto 0;">
      <img height="200" src="assets/logo.jpeg"/>
      <!-- <h2>Tumani SMS</h2> -->
    </div>
    <form
      (ngSubmit)="newPassword(newPasswordForm.value)"
      [formGroup]="newPasswordForm"
      class="login-form"
      nz-form
    >
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your password!">
          <nz-input-group nzPrefixIcon="lock">
            <input
              (ngModelChange)="updateConfirmValidator()"
              formControlName="password"
              id="password"
              nz-input
              placeholder="New Password"
              type="password"
            />
          </nz-input-group>

        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzErrorTip]="errorTpl">
          <nz-input-group nzPrefixIcon="lock">
            <input formControlName="checkPassword" id="checkPassword" nz-input placeholder="Confirm New Password"
                   type="password"/>
          </nz-input-group>

          <ng-template #errorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              Please confirm your password!
            </ng-container>
            <ng-container *ngIf="control.hasError('confirm')">
              Two passwords that you enter is inconsistent!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <button
            [nzType]="'primary'"
            class="login-form-button"
            nz-button
            style="width: 100%;"
          >
            Create Password
          </button>

        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</nz-spin>
