import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-validate-email-sent-message',
  templateUrl: './validate-email-sent-message.component.html',
  styleUrls: ['./validate-email-sent-message.component.scss']
})
export class ValidateEmailSentMessageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
