<div class="main-content">
  <nz-card>
    <div class="devInfo">
      <nz-descriptions nzBordered nzTitle="SMS URL">
        <nz-descriptions-item nzTitle="url">{{ this.smsurl }}</nz-descriptions-item>
      </nz-descriptions>
      <nz-descriptions nzBordered nzTitle="Developer Info">
        <nz-descriptions-item nzTitle="userId">{{ this.userId }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="key">{{ this.key }}</nz-descriptions-item>
      </nz-descriptions>
    </div>

    <!-- timeline -->
    <div class="devTimeline">
      <nz-descriptions nzTitle="SMS Request">
        <nz-descriptions-item>
          <nz-timeline>
            <!-- required parameters -->
            <nz-timeline-item>
              <h3>Required Parameters</h3>
              <div class="devResponse">
                <nz-table #smallTable [nzData]="requestParameters" nzSize="small">
                  <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>Description</th>
                    <th>Sample</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let data of requestParameters">
                    <td>{{ data.parameter }}</td>
                    <td>{{ data.description }}</td>
                    <td>{{ data.sample }}</td>
                  </tr>
                  </tbody>
                </nz-table>
              </div>
            </nz-timeline-item>

            <!-- sample request -->
            <nz-timeline-item>
              <h3>Sample Request</h3>
              <div nz-row>
                <div nz-col nzSpan="12">
                  <img src="./../../../assets/sample-bulk-sms-request.PNG">
                </div>
                <div class="listArea" nz-col nzSpan="12">
                  <nz-comment>
                    <!-- <h3 [ngStyle]="{ margin: '16px 0' }">Small Size</h3> -->
                    <nz-list nzSize="small">
                      <!-- <nz-list-header>Header</nz-list-header> -->
                      <nz-list-item *ngFor="let item of requestList">{{ item }}</nz-list-item>
                      <!-- <nz-list-footer>Footer</nz-list-footer> -->
                    </nz-list>
                  </nz-comment>
                </div>
              </div>
            </nz-timeline-item>

            <!-- sample response -->
            <nz-timeline-item>
              <h3>Sample Response</h3>
              <div nz-row>
                <div class="listArea" nz-col nzSpan="12">
                  <nz-comment>

                    <nz-list nzSize="small">

                      <nz-list-item *ngFor="let item of responseList">{{ item }}</nz-list-item>
                    </nz-list>
                  </nz-comment>
                </div>
                <div nz-col nzSpan="12">
                  <img src="./../../../assets/sample-bulk-sms-response.PNG">
                </div>
              </div>
            </nz-timeline-item>

          </nz-timeline>
        </nz-descriptions-item>
      </nz-descriptions>
    </div>

    <!-- table -->
    <nz-descriptions nzTitle="Response Codes">
      <nz-descriptions-item>
        <div class="devResponse">
          <nz-table #smallTable [nzData]="responseTableData" nzSize="small">
            <thead>
            <tr>
              <th>Response Code</th>
              <th>Response Description</th>
              <th>Details</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of responseTableData">
              <td>{{ data.responseCode }}</td>
              <td>{{ data.responseDescription }}</td>
              <td>{{ data.responseDetails }}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-descriptions-item>
    </nz-descriptions>
  </nz-card>

  <nz-card>
    <nz-descriptions nzTitle="Need Assistance?">

    </nz-descriptions>
  </nz-card>
</div>
