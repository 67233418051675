import {Component, OnInit} from '@angular/core';
import {NzMessageService, UploadChangeParam} from 'ng-zorro-antd';
import * as XLSX from 'xlsx';
import './messageBodyInterface';
import {SendSmsService} from 'src/app/services/send-sms.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

type AOA = any[][];

@Component({
  selector: 'app-personalized-sms',
  templateUrl: './personalized-sms.component.html',
  styleUrls: ['./personalized-sms.component.scss']
})
export class PersonalizedSmsComponent implements OnInit {


  public displayUploadedSheet = false;
  public displayUploadSection = true;
  isNextDisabled = true;
  fileLocation: any;
  bulkData: any;
  headData: any;
  headerTitle: any;
  storedMessages = [];
  storedNumber = [];
  uploadRequest = new XMLHttpRequest();
  messageObject: any;
  jsonMessageObject: any;
  messageTemplate: any;
  sampleMessage: any;
  sampleMessageContact: any;
  originatorId: any;
  clientMessage: any;
  userId: string;
  key: string;
  userItems: any;
  sampleItems = [{
    sampleMessage: this.sampleMessage,
    messageTemplate: this.messageTemplate
  }]
  messageToSend: string = '';
  validateForm: FormGroup;
  current = 0;
  index = 0;

  constructor(private msg: NzMessageService, private readonly personalizedService: SendSmsService, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      originatorId: [null, [Validators.required]],
      msisdn: [null, [Validators.required]],
      message: [null, [Validators.required]]
    });
  }

  pre(): void {
    this.current -= 1;
    this.changeContent();
  }

  next(): void {
    console.log('current ==> ' + this.current)
    this.current += 1;
    this.changeContent();

    if (this.current == 1) {
      this.sampleMessage = this.storedMessages[0];
      this.sampleMessageContact = this.storedNumber[0];

      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
  }

  done(value): void {
    this.userItems = this.getFromLocalStrorage();
    this.userId = this.userItems.userId;
    this.key = this.userItems.key;
    console.log('done');

    for (let j = 0; j < this.bulkData.length; j++) {

      let bulkDataArray = this.bulkData[j];

      var mapObj = {
        param1: bulkDataArray[0],
        param2: bulkDataArray[1],
        param3: bulkDataArray[2],
        param4: bulkDataArray[3],
        param5: bulkDataArray[4],
        param6: bulkDataArray[5],
        param7: bulkDataArray[6],
        param8: bulkDataArray[7]
      };
      console.log('Client Message =>' + this.clientMessage)
      var personalizedMessage = this.clientMessage.replace(/param1|param2|param3|param4|param5|param6|param7|param8/gi, function (matched) {
        return mapObj[matched];
      });
      console.log('personalizedMessage => ' + personalizedMessage)
      console.log('number to send to => ' + bulkDataArray[0]);
      console.log('OrginatorId => ' + this.originatorId)

      for (var i = 0; i < this.storedNumber.length; i++) {
        this.messageObject['originatorId'] = this.originatorId
        this.messageObject['msisdn'] = this.storedNumber[i]
        this.messageObject['message'] = this.storedMessages[i]
        this.messageObject['userId'] = this.userId
        this.messageObject['key'] = this.key
        console.log('sending this => ' + this.messageObject)
        this.personalizedService.sendMessage(this.messageObject).subscribe(res => {
          console.log('send personalised messaged response => ' + res);
        })

      }

      console.log('current index => ' + i)
      const something = this.storedNumber.length
      console.log('array length => ' + something)
      if (i === something) {
        this.msg.create('success', `Messages Sucessfully sent`);
        this.current = 0;
        this.changeContent();
      }
    }
  }

  getOriginator(evt) {
    console.log('originator id => ' + evt.target.value)
    this.originatorId = evt.target.value
  }

  customiseMessage(evt) {
    this.clientMessage = evt.target.value
    var clientMessage = evt.target.value;
    this.messageTemplate = evt.target.value;
    console.log('message template => ' + this.messageTemplate)

    for (let j = 0; j < this.bulkData.length; j++) {
      let bulkDataArray = this.bulkData[j];

      var mapObj = {
        param1: bulkDataArray[0],
        param2: bulkDataArray[1],
        param3: bulkDataArray[2],
        param4: bulkDataArray[3],
        param5: bulkDataArray[4],
        param6: bulkDataArray[5],
        param7: bulkDataArray[6],
        param8: bulkDataArray[7]
      };
      var personalizedMessage = clientMessage.replace(/param1|param2|param3|param4|param5|param6|param7|param8/gi, function (matched) {
        return mapObj[matched];
      });
      console.log('personalizedMessage => ' + personalizedMessage)
      console.log('number to send to => ' + bulkDataArray[0]);
      this.storedMessages.push(personalizedMessage);
      this.storedNumber.push(bulkDataArray[0]);

    }
    console.log('personlized message =>' + this.storedMessages);
    console.log('stored number =>' + this.storedNumber);

    console.log('sample message => ' + this.storedMessages)
    this.sampleMessage = this.storedMessages[0];
    this.sampleMessageContact = this.storedNumber[0];

    this.messageObject = {}

  }

  changeContent(): void {
    switch (this.current) {
      case 0: {
        this.index = 0;
        break;
      }
      case 1: {
        this.index = 1;
        break;
      }
      case 2: {
        this.index = 2;
        break;
      }
      default: {
        this.index = 0;
      }
    }
  }

  stateHandler() {
    this.displayUploadedSheet = true;
    this.displayUploadSection = false;
  }

  handleChange({file, fileList}: UploadChangeParam): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.isNextDisabled = false;
      this.msg.success(`${file.name} file uploaded successfully.`);
      this.fileLocation = file.response
      // set table display to true
      //this.displayUploadedSheet = true;

      this.uploadRequest.open("GET", this.fileLocation, true);
      this.uploadRequest.responseType = "arraybuffer";

      console.log("*************************************");
      this.uploadRequest.onload = (e) => {
        const arraybuffer = this.uploadRequest.response;
        console.log(arraybuffer);

        //convert data to binary string
        const data = new Uint8Array(arraybuffer);
        const arr = new Array();
        console.log(data.length);

        for (let i = 0; i !== data.length; ++i) {
          arr[i] = String.fromCharCode(data[i]);
        }
        const bstr = arr.join("");

        // call XLSX
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: "binary"});

        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        this.bulkData = <AOA>(
          XLSX.utils.sheet_to_json(ws, {header: 1, raw: false, range: 0})
        );
        console.log(this.headData);
        console.log('-------------------------------------');
        console.log(this.bulkData);
        this.headData = this.bulkData[0];
        this.headerTitle = this.bulkData.slice(0, 1);
        this.bulkData = this.bulkData.slice(1);

      };

      this.uploadRequest.send()

    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  public getFromLocalStrorage() {
    const users = JSON.parse(localStorage.getItem('currentUser'));
    console.log('============');
    console.log(users);
    return users;
  }

}
