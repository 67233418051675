import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SendSmsService} from 'src/app/services/send-sms.service';
import {NzMessageService, NzNotificationService} from 'ng-zorro-antd';

@Component({
  selector: 'app-quick-sms',
  templateUrl: './quick-sms.component.html',
  styleUrls: ['./quick-sms.component.scss']
})
export class QuickSmsComponent implements OnInit {

  value = '';
  title = 'Input a number';
  userItems: any;
  validateForm: FormGroup;
  @ViewChild('inputElement', {static: false}) inputElement: ElementRef;

  constructor(private fb: FormBuilder, private readonly quickSmsService: SendSmsService,
              private notification: NzNotificationService) {
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      originatorId: [null, [Validators.required]],
      msisdn: [null, [Validators.required]],
      message: [null, [Validators.required]]
    });

    this.userItems = this.getFromLocalStrorage();

  }

  public getFromLocalStrorage() {
    const users = JSON.parse(localStorage.getItem('currentUser'));

    return users;
  }

  onChange(value: string): void {
    this.updateValue(value);
  }

  updateValue(value: string): void {
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(+value) && reg.test(value)) || value === '' || value === '-') {
      this.value = value;
    }
    this.inputElement.nativeElement.value = this.value;
    this.updateTitle();
  }

  updateTitle(): void {
    this.title = (this.value !== '-' ? this.formatNumber(this.value) : '-') || 'Input a number';
  }

  formatNumber(value: string): string {
    const string = `${value}`;
    const list = string.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  }

  submitForm(formData) {

    const userId = this.userItems.userId;
    const key = this.userItems.key;

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    formData.userId = userId;
    formData.key = key;
    formData.msisdn = 260 + formData.msisdn;

    if (this.validateForm.valid) {

      return this.quickSmsService.sendMessage(formData).subscribe(res => {
        if (res === '00') {
          this.notification.create(
            "success",
            'Successful',
            'Your Message was successfully sent.'
          );
          this.validateForm.reset();
        } else {
          this.notification.create(
            "Failed",
            'Failed',
            'Failed To send a message'
          );
        }

      });
    }
  }


}
