import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../models/users';
import {Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd';
import {BaseUrlService} from "./baseUrl.service";

// const BASE_URL = "https://bulk-sms-api.herokuapp.com";
// const BASE_URL = "http://localhost:3331";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  constructor(private http: HttpClient, private readonly router: Router,
              private message: NzMessageService,
              private readonly baseUrlService: BaseUrlService

  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${this.baseUrlService.BASE_URL}/auth/login`, {email, password})
      .pipe(
        map(user => {
          if (user && user.access_token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          } else {

          }

          return user;
        })
      )
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  loginError(error) {
    if (error === 400) {
      this.message.error("Wrong credentials, Kindly check your password or email")
    }
  }

  forgotPassword(email) {
    console.log("Email IN Auth Service", email)
    return this.http.post<any>(`${this.baseUrlService.BASE_URL}/auth/forgot-password`, {email});
  }

  updateUserPassword(uuid: string, newPassword: string) {
    console.log("Passwrod IN Auth Service", newPassword)
    return this.http.post<any>(`${this.baseUrlService.BASE_URL}/auth/reset/${uuid}`, {newPassword});
  }

}
