import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Message} from './../models/messages.model';
import {BaseUrlService} from "./baseUrl.service";



@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private http: HttpClient,
  private readonly baseUrlService: BaseUrlService
  ) {
  }

  getAllMessages(userId: string) {

    return this.http.get<Message[]>(`${this.baseUrlService.BASE_URL}/sms-overview/userId/${userId}`);
  }

  getMessageByFailedStatus(messageStatus: any) {
    return this.http.get<Message[]>(`${this.baseUrlService.BASE_URL}/sms-overview/failed/${messageStatus}`);
  }

  getMessageBySuccessStatus(messageStatus: any) {
    return this.http.get<Message[]>(`${this.baseUrlService.BASE_URL}/sms-overview/success/${messageStatus}`);
  }


}
