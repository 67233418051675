import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {NzTimelineModule} from 'ng-zorro-antd/timeline';
import {AppComponent} from './app.component';
import {NgZorroAntdModule, NZ_I18N, en_US, NzFormModule} from 'ng-zorro-antd';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {DashboardComponent} from './dashboard/dashboard.component';
import {WelcomeComponent} from './pages/welcome/welcome.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {QuickSmsComponent} from './pages/quick-sms/quick-sms.component';
import {InsightsComponent} from './pages/insights/insights.component';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {SmsOverViewComponent} from './sms-over-view/sms-over-view.component';
import {BulkSmsComponent} from './pages/bulk-sms/bulk-sms.component';
import {PersonalizedSmsComponent} from './pages/personalized-sms/personalized-sms.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {LoginComponent} from './login/login.component';
import {DevelopersComponent} from './pages/developers/developers.component';
import {MyAccountComponent} from './pages/my-account/my-account.component';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzCommentModule} from 'ng-zorro-antd/comment';
import {NzDescriptionsModule} from 'ng-zorro-antd/descriptions';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {NavbarComponent} from './landing-page/components/navbar/navbar.component';
import {HeroComponent} from './landing-page/components/hero/hero.component';
import {ServiceComponent} from './landing-page/components/service/service.component';
import {PricingComponent} from './landing-page/components/pricing/pricing.component';
import {AboutComponent} from './landing-page/components/about/about.component';
import {FooterComponent} from './landing-page/components/footer/footer.component';
import {ContactsusComponent} from './landing-page/components/contactsus/contactsus.component';
import {LandingDevelopersComponent} from './landing-page/components/landing-developers/landing-developers.component';
import {ErrorIntercept} from './interceptor/error.interceptor';
import {TermsOfUseComponent} from './landing-page/components/terms-of-use/terms-of-use.component';
import {TermsComponent} from './landing-page/components/terms-of-use/components/terms/terms.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {NewPaswordComponent} from './new-pasword/new-pasword.component';
import {EmailSentMessageComponent} from './email-sent-message/email-sent-message.component';
import {ValidateEmailSentMessageComponent} from './validate-email-sent-message/validate-email-sent-message.component';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';

registerLocaleData(en);

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    WelcomeComponent,
    QuickSmsComponent,
    InsightsComponent,
    SmsOverViewComponent,
    PersonalizedSmsComponent,
    LoginComponent,
    DevelopersComponent,
    MyAccountComponent,
    LandingPageComponent,
    NavbarComponent,
    HeroComponent,
    ServiceComponent,
    PricingComponent,
    AboutComponent,
    FooterComponent,
    ContactsusComponent,
    LandingDevelopersComponent,
    TermsOfUseComponent,
    TermsComponent,
    ForgotPasswordComponent,
    NewPaswordComponent,
    EmailSentMessageComponent,
    ValidateEmailSentMessageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    NzListModule,
    NzDescriptionsModule,
    NzCommentModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    NzFormModule,
    NzTimelineModule,
    ScrollingModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    LottieModule.forRoot({player: playerFactory})
  ],
  providers: [
    {provide: NZ_I18N, useValue: en_US},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorIntercept,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
