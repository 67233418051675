import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {NzMessageService} from 'ng-zorro-antd';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;
  password: string;
  validateStatus: string;
  forgotPasswordForm: FormGroup;
  isLoggingIn = false;

  constructor(
    private fb: FormBuilder,
    private readonly router: Router,
    private msg: NzMessageService,
    private readonly authenticationService: AuthenticationService,
    private message: NzMessageService
  ) {
  }

  ngOnInit(): void {
    this.authenticationService.logout();
    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }


  forgorPassword(payload) {

    for (const i in this.forgotPasswordForm.controls) {
      this.forgotPasswordForm.controls[i].markAsDirty();
      this.forgotPasswordForm.controls[i].updateValueAndValidity();
    }
    if (!this.forgotPasswordForm.invalid) {
      this.isLoggingIn = true;
      this.authenticationService.forgotPassword(payload.email).subscribe(res => {
        if (res === 200) {
          this.msg.success('Email Created Successfully.');
          this.router.navigateByUrl('/email-sent');
        } else {
          this.msg.error('Failed to Send Email');
          this.isLoggingIn = false;
        }

      })
      setTimeout(() => {
        this.isLoggingIn = false;
      }, 1000);

    }
  }

}
