import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Transactions} from '../models/transactions.model';
import {Observable} from 'rxjs';
import {SMSPaymentRequest} from "../models/SmsPaymentRequest";
import {BaseUrlService} from "./baseUrl.service";



@Injectable({
  providedIn: 'root'
})
export class SmsPaymentService {

  constructor(private http: HttpClient,
  private readonly baseUrlService: BaseUrlService

) {
  }


  sendPaymentRequest(payload:SMSPaymentRequest): any {
    return this.http.post(`${this.baseUrlService.BASE_URL}/payment`, payload);
  }

  getPaymentTransactions(userId: string): Observable<Transactions[]> {
    console.log('user name => ' + userId)
    return this.http.get<Transactions[]>(`${this.baseUrlService.BASE_URL}/sms-payment/payment-history/${userId}`)
  }
}
