<nz-spin [nzSpinning]="isLoggingIn">
  <div style="margin: 0 auto; width: 400px; height: 100%;">
    <div class="logo" style="text-align: center; margin: 70px auto 0;">
      <img height="200" src="assets/logo.jpeg"/>
      <!-- <h2>Tumani SMS</h2> -->
    </div>
    <form
      (ngSubmit)="forgorPassword(forgotPasswordForm.value)"
      [formGroup]="forgotPasswordForm"
      class="login-form"
      nz-form
    >
      <nz-form-item>
        <nz-form-control
          [nzHasFeedback]="true"
          [nzValidateStatus]="validateStatus"
          nzErrorTip="Please check your email address."
        >
          <nz-input-group nzPrefixIcon="mail">
            <input
              formControlName="email"
              nz-input
              placeholder="Email address"
              type="text"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control>
          <button
            [nzType]="'primary'"
            class="login-form-button"
            nz-button
            style="width: 100%;"
          >
            Get Reset Link
          </button>

        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</nz-spin>
