<nz-card>
  <nz-steps [nzCurrent]="current">
    <nz-step nzTitle="Import File"></nz-step>
    <nz-step nzTitle="Compose Message"></nz-step>
    <nz-step nzTitle="Verify Message"></nz-step>
  </nz-steps>

  <div class="steps-content">
    <div *ngIf="index == 0">
      <div class="commentContainer">
        <nz-comment-content>
          <div nz-row>
            <div class="tips" nz-col nzSpan="12">
              <nz-alert
                nzDescription="Upload an excel sheet with upto 7 paramters of your choice"
                nzMessage="Tip"
                nzShowIcon
                nzType="info"
              >
              </nz-alert>
            </div>
            <div class="tips" nz-col nzSpan="12">
              <nz-alert
                nzDescription="First Column should contain recipients phone number"
                nzMessage="Take Note"
                nzShowIcon
                nzType="warning"
              >
              </nz-alert>
            </div>
          </div>

        </nz-comment-content>

      </div>


      <nz-upload
        (click)="stateHandler()"
        (nzChange)="handleChange($event)"
        [nzLimit]="2"
        nzAction="https://bulk-sms-api.herokuapp.com/bulk-uploads/azure/upload"
        nzType="drag"
      >
        <p class="ant-upload-drag-icon">
          <i nz-icon nzType="inbox"></i>
        </p>
        <p class="ant-upload-text">Click or drag file to this area to upload excel sheet</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files.
        </p>


        <div class="tableContainer">
          <nz-table #basicTable *ngIf="displayUploadedSheet" [nzData]="bulkData">
            <thead>
            <tr>
              <th *ngFor="let headValue of headData">
                {{ headValue }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of bulkData">
              <td *ngFor="let val of headData; let i =index">
                {{ row[i] || ' ' }}
              </td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </nz-upload>

    </div>

    <div *ngIf="index == 1">
      <div>
        <div class="commentContainer">
          <nz-comment-content>
            <nz-alert
              nzDescription="param1 = A , param2 = B , param3 = C , param4 = D , param5 = E , param6 = F , param7 = G"
              nzMessage="Guidelines"
              nzType="info">
            </nz-alert>
          </nz-comment-content>
        </div>

        <div class="container">
          <form nz-form>

            <!-- Orignator section of form -->
            <div nz-row>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please enter Originator!" nzRequired>
                    <input
                      (change)="getOriginator($event)"
                      nz-input
                      placeholder="Enter Originator"/>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <!-- Message Section of form -->
            <div nz-row>
              <nz-form-item>
                <nz-form-control nzErrorTip="Please write something here!">
                        <textarea
                          (change)="customiseMessage($event)"
                          nz-input
                          placeholder="Type your message here"
                          rows="8"
                        ></textarea>
                  <!-- (keyup)="message($event)" -->
                </nz-form-control>
              </nz-form-item>
            </div>

          </form>
        </div>
      </div>

    </div>

    <div *ngIf="index == 2">
      <div nz-row>
        <div class="cardContainer" nz-col nzSpan="12">
          <nz-card nzTitle="Message Template">
            <p> {{ this.messageTemplate }} </p>

          </nz-card>
        </div>
        <div class="cardContainer" nz-col nzSpan="12">
          <nz-card nzTitle="Sample Personalized Message">
            <p> {{ this.sampleMessage }}</p>
          </nz-card>
        </div>
      </div>
    </div>

  </div>

  <div class="steps-action">
    <button
      (click)="pre()"
      *ngIf="current > 0"
      nz-button
      nzShape="round"
      nzType="default"
      style="width: 200px;"
    >
      <span> Previous </span>
    </button>
    <button
      (click)="next()"
      *ngIf="current < 2"
      [disabled]="isNextDisabled"
      nz-button
      nzShape="round"
      nzType="default"
      style="width: 200px;"
    >
      <span>Next</span>
    </button>
    <button
      (click)="done($event)"
      *ngIf="current === 2"
      nz-button
      nzShape="round"
      nzType="default"
      style="width: 200px;"
    >
      <span><i nz-icon nzTheme="outline" nzType="mail"></i> Send </span>
    </button>
  </div>
</nz-card>
