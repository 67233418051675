import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from 'src/app/services/user.service';
import {NzMessageService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {UsersDTO} from 'src/app/models/user.dto';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  isVisible = false;
  isOkLoading = false;
  validateForm: FormGroup;
  checked = false;

  animationOptions: AnimationOptions = {
    path: 'assets/send-message.json'
  };

  constructor(
    private fb: FormBuilder,
    private readonly usersService: UserService,
    private msg: NzMessageService,
    private readonly router: Router,
    private readonly authService: AuthenticationService,
  ) {
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return {required: true};
    } else if (control.value !== this.validateForm.controls.password.value) {
      return {confirm: true, error: true};
    }
    return {};
  };

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      msisdn: [null, [Validators.required]],
      //address: [null, [Validators.required]],
      agree: [false, [Validators.requiredTrue]]
    });
  }


  showModal(): void {
    this.isVisible = true;
  }

  handleOk(data: UsersDTO): void {

    data.msisdn = "260" + data.msisdn

    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid && this.checked === true) {
      this.isOkLoading = true;
      setTimeout(() => {

        this.usersService.createUser(data).subscribe(res => {
          if (res.status === 200) {
            this.msg.success('Registered Successfully.')
            this.isVisible = false;
            this.isOkLoading = false;
            this.authService.logout();
            this.router.navigateByUrl('/validate-email-sent');
          } else if (res.status === 409 && res.message === 'User Already Exists') {
            this.msg.error('User Already Exist.');
            this.isOkLoading = false;
          } else {
            this.msg.error('Failed to create account')
            this.isOkLoading = false;
          }
        })

      }, 5000);

    }

    this.validateForm.get('agree').markAsTouched();

  }

  login() {
    this.router.navigateByUrl('/login');
  }

  handleCancel(): void {
    this.isVisible = false;
  }

}
