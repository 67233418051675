<!-- ======= Hero Section ======= -->
<section class="d-flex align-items-center" id="hero">

  <div class="container">
    <div class="row">
      <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
        <h1 data-aos="fade-up">Centralize your customer communication with Tumani</h1>
        <h2 data-aos="fade-up" data-aos-delay="400">Engage customers through seamless automation.</h2>
        <div data-aos="fade-up" data-aos-delay="800">
          <a (click)="showModal()" class="btn-get-started scrollto">Get Started</a>
        </div>
      </div>
      <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
        <!-- <img src="assets/progressive_app_m9ms.png" class="img-fluid animated" alt=""> -->
        <ng-lottie [options]="animationOptions"></ng-lottie>
      </div>
    </div>
  </div>

</section><!-- End Hero -->

<nz-modal
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk(validateForm.value)"
  [(nzVisible)]="isVisible"
  [nzOkLoading]="isOkLoading"
  nzTitle="Register"
  style="width: 800px;"
>
  <form [formGroup]="validateForm" nz-form>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="name" nzRequired>Name</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input you name">
        <input formControlName="name" id="name" nz-input/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="email" nzRequired>E-mail</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not valid E-mail!">
        <input formControlName="email" id="email" nz-input/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="password" nzRequired>Password</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input your password! minimum characters should be 6">
        <input
          (ngModelChange)="updateConfirmValidator()"
          formControlName="password"
          id="password"
          minlength="6"
          nz-input
          type="password"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="checkPassword" nzRequired>Confirm Password</nz-form-label>
      <nz-form-control [nzErrorTip]="errorTpl" [nzSm]="14" [nzXs]="24">
        <input formControlName="checkPassword" id="checkPassword" nz-input type="password"/>
        <ng-template #errorTpl let-control>
          <ng-container *ngIf="control.hasError('required')">
            Please confirm your password!
          </ng-container>
          <ng-container *ngIf="control.hasError('confirm')">
            Two passwords that you enter is inconsistent!
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzFor="phoneNumber" nzRequired>Phone Number</nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzValidateStatus]="validateForm.controls['msisdn']"
        [nzXs]="24"
        nzErrorTip="Please input valid phone number!"
        phoneNumberPrefix
      >
        <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
          <ng-template #addOnBeforeTemplate>
            +260
          </ng-template>
          <input formControlName="msisdn" id="'phoneNumber'" maxlength="9" minlength="9" nz-input/>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <!-- <nz-form-item>
      <nz-form-label [nzSm]="7" [nzXs]="24" nzRequired nzFor="address">Address</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please input you address">
        <input nz-input formControlName="address" id="address"/>
      </nz-form-control>
    </nz-form-item> -->

    <nz-form-item class="register-area" nz-row>
      <nz-form-control [nzOffset]="6" [nzSpan]="14" nzErrorTip="Please accept terms and conditions to proceed!">
        <label formControlName="agree" nz-checkbox>
          <span>I have read the <a routerLink="/terms-of-use">agreement</a></span>
        </label>
      </nz-form-control>
    </nz-form-item>

  </form>
</nz-modal>
